import React, { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DeleteConfirmation } from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook
import { ExpenseInsuranceAccordionNames } from '../../constants/BuildTabComponentV3';

const [
  TERMLIFEINS,
  WHOLELIFEINS,
  UMBRELLAINS,
  LONGTERMCARE,
  HEALTHCARE,
  PROPERTYINSURANCE,
  AUTOINS,
] = ExpenseInsuranceAccordionNames;

export const BuildExpensesInsurancesComponent = (props) => {
  const {
    state,
    memberOptions,
    setLastStepInParent,
    isFormValid,
    formErrors,
    handleAccordionChange,
    expandedPanel,
    renderTermLifeInsuranceComponent,
    renderWholeLifeInsuranceComponent,
    renderUmbrellaLifeInsuranceComponent,
    renderLongTermCareInsuranceComponent,
    renderHealthCareInsuranceComponent,
    renderPropertyInsuranceComponent,
    renderBuildVehicleInsuranceComponent,
  } = props;

  const { formDetails, isEditable, isAddNew } = state;

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      <div className="Accordion_outer faq-container-new">
        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === TERMLIFEINS}
          onChange={handleAccordionChange(TERMLIFEINS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Term Life</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === TERMLIFEINS &&
              renderTermLifeInsuranceComponent()}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === WHOLELIFEINS}
          onChange={handleAccordionChange(WHOLELIFEINS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Whole Life</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === WHOLELIFEINS &&
              renderWholeLifeInsuranceComponent()}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === LONGTERMCARE}
          onChange={handleAccordionChange(LONGTERMCARE)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>Long Term Care</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === LONGTERMCARE &&
              renderLongTermCareInsuranceComponent()}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === UMBRELLAINS}
          onChange={handleAccordionChange(UMBRELLAINS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Umbrella</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === UMBRELLAINS &&
              renderUmbrellaLifeInsuranceComponent()}{' '}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === PROPERTYINSURANCE}
          onChange={handleAccordionChange(PROPERTYINSURANCE)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>Property</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === PROPERTYINSURANCE &&
              renderPropertyInsuranceComponent()}
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === AUTOINS}
          onChange={handleAccordionChange(AUTOINS)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Vehicle</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === AUTOINS &&
              renderBuildVehicleInsuranceComponent()}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="faq-content-new"
          expanded={expandedPanel === HEALTHCARE}
          onChange={handleAccordionChange(HEALTHCARE)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>Health</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandedPanel === HEALTHCARE &&
              renderHealthCareInsuranceComponent()}
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="tab_main_nav">
        <div className="tab_left_nav">
          <button>
            <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className="tab_right_nav">
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      {/* {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )} */}
    </>
  );
};

export default BuildExpensesInsurancesComponent;
