import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { renderSelectOption } from '../../../helpers/BuildHelperComponentV3';

const ModelComponent = forwardRef((props, ref) => {
  const {
    planDetailsByPlanId,
    updatePlanDetailsInRedux,
    setLastStepInParent,
    horizonMenuOptions,
    displayTermMenuOptions,
  } = props;
  const { moduleSettings: { model = {} } = {} } = planDetailsByPlanId;
  const [modelDetails, setModelDetails] = useState(model);

  useImperativeHandle(ref, () => ({
    getModelDetails() {
      return modelDetails;
    },
  }));

  const handleFieldChange = (event, name) => {
    const { value } = event.target;
    const updatedModelDetails = {
      ...modelDetails,
      [name]: value,
    };
    setModelDetails(updatedModelDetails);
  };

  const renderInputComp = (label, valueType, type = 'text', name) => {
    return (
      <div className="row align-items-center" key={name}>
        <div className={`col-lg-9 col-sm-8 col-6 insured_content_outer`}>
          <p>{label}</p>
        </div>
        <div className={`col-lg-3 col-sm-4 col-6 insured_radio_group`}>
          <input
            type={type}
            // id={firstInputIdName}
            name={name}
            onKeyDown={(event) => {
              if (event.key === '.' && valueType === 'number') {
                event.preventDefault();
              }
            }}
            value={modelDetails[name]}
            className={'custom-input'} // Apply the appropriate class
            onChange={(event) => handleFieldChange(event, name)}
            placeholder=""
          />
        </div>
        {/* {!isFieldValid && <p className="error-text">{validationMessage}</p>} */}
      </div>
    );
  };

  return (
    <div className="insured_main_outer">
      {renderInputComp(
        `Simulate until younger person's age`,
        'number',
        'text',
        'youngerAgeAtEstateTransfer'
      )}
      {renderSelectOption(
        'text',
        'tlHorizon',
        'horizon',
        'Planning Horizion Years',
        horizonMenuOptions,
        modelDetails,
        'model',
        planDetailsByPlanId,
        updatePlanDetailsInRedux,
        'moduleSettings',
        false,
        setModelDetails
      )}
      {renderSelectOption(
        'text',
        'tlDisplayTerm',
        'displayTerm',
        'Display Periods',
        displayTermMenuOptions,
        modelDetails,
        'model',
        planDetailsByPlanId,
        updatePlanDetailsInRedux,
        'moduleSettings',
        false,
        setModelDetails
      )}
    </div>
  );
});

export default ModelComponent;
