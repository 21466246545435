import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import settingDollar from '../../images/setting-dollar.svg';

class FirstCardComonent extends Component {
  render() {
    const {
      activeCard,
      isCompanyAssetsLoading,
      setActiveCardState,
      transformValue,
      advisorAssetsUnderManagement: { aum },
      companyAssetsUnderManagement: { aum: companyAUM },
    } = this.props;
    return (
      <div
        className={`card home_main_card_outer one mb-4 ${
          activeCard === 'assets' ? `active` : ``
        }`}
        onClick={() => setActiveCardState('assets')}
      >
        <div className="card-body assets_under_management_inner">
          <h5 className="text-white">Business Review</h5>
          <div className="Under_Management d-md-flex">
            <div className="Under_Management_image">
              <img src={settingDollar} alt="" title="" />
            </div>
            {isCompanyAssetsLoading ? (
              <CircularProgress
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                }}
                size={20}
              />
            ) : (
              <div className="Under_Management_content d-md-flex">
                <div className="Under_Management_content_left">
                  <p>Company AUM</p>
                  <h4>
                    <span className="value">
                      {(companyAUM && transformValue(companyAUM)) || '$0'}
                    </span>
                  </h4>
                </div>
                <div className="Under_Management_content_left">
                  <p>Advisor AUM</p>
                  <h4>
                    <span className="value">
                      {(aum && transformValue(aum)) || '$0'}
                    </span>
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FirstCardComonent;
