import React, { useEffect } from 'react';
import {
  renderFormInputRow,
  renderFooterSubmitCancelButtons,
  renderFormInputRowName,
  renderRadioGroup,
  renderFormDateField,
  renderSelectAccountDropdown,
  renderFormSelectOption,
  DeleteConfirmation,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const BuildExpensesVacationsComponent = (props) => {
  const {
    state,
    concatContent,
    setLastStepInParent,
    isFormValid,
    formErrors,
    handleValidations,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleFamily',
    mainTab: 'infoVacations',
    subTab: 'vacations',
    countVal: 'numVacations',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName, 
          'Select Vacation', 
          openDeleteConfirmation,
          props,
          state,
          moduleObject
        )}
      </div>
      {(isAddNew || isEditable) && (
        <div className="insured_main_outer">
          {renderFormInputRowName(
            'text',
            'tlName',
            'name',
            'Vacation Name',
            'text',
            state,
            moduleObject,
            props,
            finalExistingItems,
            true,
            formErrors,
            isAddNew
          )}
        </div>
      )}
      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          {renderFormDateField(
            'vacationYear',
            'Year in which you plan to take',
            'text',
            moduleObject,
            state,
            props,
            'year',
            true, //isMandatory
            true, //disable past
            formErrors,
            isAddNew,
            isEditable
          )}
          {renderFormInputRow(
            'text',
            'tlVacationExpense',
            'vacationExpense',
            'How much would it cost?',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
};

export default BuildExpensesVacationsComponent;
