import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import ReactRouter from './navigation/Router';
import { Router, Route, Switch, useLocation } from 'react-router-dom';
import history from './history';
import './styles/retirementPlan.scss';
import './styles/newUIV5.scss';
import './styles/responsive.scss';
import Website from './components/Website';
import 'core-js/features/url-search-params';
import { SnackbarProvider } from 'notistack';
import { initializeAnalytics, logPageView } from './analytics';

const App = () => {

  useEffect(() => {
    initializeAnalytics();
  }, []);

  const path = window.location.pathname;
  return (
    <Provider store={store}>
      <SnackbarProvider
        iconVariant={{
          info: null,
        }}
      >
        <Router history={history}>
          <PageTracker />
          <Switch>
            <Route exact path={'/'} component={Website} />
            <ReactRouter />
          </Switch>
        </Router>
      </SnackbarProvider>
    </Provider>
  );
};

// This component listens for page changes and logs page views
const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};
export default App;
