import { GET, POST, CUSTOMGET, CUSTOMPOST } from '../api/fetch_wrapper';
import { API_HOST_URL } from './apiHost';

export async function getSelectToBeApprovedUsers() {
  return GET(`${API_HOST_URL()}/selectToBeApprovedUsers`);
}

export async function approveSelectedUser(emailId, isApproved) {
  // const reqQueryStr = convertObjToQueryStr(reqPayload);
  //return POST(`http://107.20.29.93:9090/public/createUser?${reqQueryStr}`);
  return POST(
    `${API_HOST_URL()}/approveUser?emailId=${emailId}&isApproved=${isApproved}`
  );
}
