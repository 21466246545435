import {
  GET_PLANS_BY_CLIENT_ID,
  GET_PLANS_BY_CLIENT_ID_SUCCESS,
  GET_PLANS_BY_CLIENT_ID_ERROR,
  GET_MEETINGDETAILS_BY_MEETING_ID,
  GET_MEETINGDETAILS_BY_MEETING_ID_ERROR,
  GET_MEETINGDETAILS_BY_MEETING_SUCCESS,
  SAVE_MEETINGDETAILS_BY_MEETINGID,
  SAVE_MEETINGDETAILS_BY_MEETINGID_SUCCESS,
  SAVE_MEETINGDETAILS_BY_MEETINGID_ERROR,
  UPDATE_USER_MOBILE,
  UPDATE_USER_MOBILE_SUCCESS,
  UPDATE_USER_MOBILE_ERROR,
  GET_USERDETAILS_BY_EMAIL_ID,
  GET_USERDETAILS_BY_EMAIL_ID_SUCCESS,
  GET_USERDETAILS_BY_EMAIL_ID_ERROR,
  SET_SELECTED_PLANID_FOR_CLIENTID,
  SET_SELECTED_HORIZON_TERM_FOR_CLIENT,
  SET_SELECTED_DISPLAY_TERM_FOR_CLIENT,
  SET_SELECTED_CHRONO_TAB_ITEM,
  SET_SELECTED_CHART,
  SET_SELECTED_TABLE,
  SET_SELECTED_DATA,
  SET_SELECTED_NUMBEROF_RUNS,
  SET_CLIENT_VIEW,
  CLEAR_SELECTED_CLIENT_PLAN_DETAILS,
  CLEAR_PUSH_PIN_CHARTS,
  PUSH_PIN_CHARTS,
} from '../actions/plansAction';

const initialState = {
  plans: [],
  selectedPlan: '',
  selectedDisplayTerm: '',
  selectedHorizonTerm: '',
  selectedChronoTabItem: '',
  selectedChart: '',
  selectedTable: '',
  selectedData: '',
  selectedNumberOfRuns: 0,
  isClientView: false,
  pushPinCharts: [],
  prevSelectedPlan: '',
};

export default function Plans(state = initialState, action) {
  switch (action.type) {
    case GET_PLANS_BY_CLIENT_ID:
      return {
        ...state,
        plans: [],
      };
    case GET_PLANS_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        plans: action.payload,
      };
    case GET_PLANS_BY_CLIENT_ID_ERROR:
      return {
        ...state,
        plans: action.payload,
      };
    case GET_MEETINGDETAILS_BY_MEETING_ID:
      return {
        ...state,
        meetingDetail: {},
      };
    case GET_MEETINGDETAILS_BY_MEETING_SUCCESS:
      return {
        ...state,
        meetingDetail: action.payload,
      };
    case GET_MEETINGDETAILS_BY_MEETING_ID_ERROR:
      return {
        ...state,
        meetingDetail: action.payload,
      };
    case GET_USERDETAILS_BY_EMAIL_ID:
      return {
        ...state,
        clientDetails: {},
      };
    case GET_USERDETAILS_BY_EMAIL_ID_SUCCESS:
      return {
        ...state,
        clientDetails: action.payload,
      };
    case GET_USERDETAILS_BY_EMAIL_ID_ERROR:
      return {
        ...state,
        clientDetails: action.payload,
      };

    case UPDATE_USER_MOBILE_SUCCESS: {
      return {
        ...state,
        isMobileNumberSaved: true,
      };
    }
    case UPDATE_USER_MOBILE_ERROR: {
      return {
        ...state,
        isMobileNumberSaved: false,
      };
    }
    case SAVE_MEETINGDETAILS_BY_MEETINGID_SUCCESS: {
      return {
        ...state,
        isMeetingDetailsSaved: true,
        meetingDetail: action.payload,
      };
    }
    case SAVE_MEETINGDETAILS_BY_MEETINGID_ERROR: {
      return {
        ...state,
        meetingDetail: action.payload,
      };
    }
    case SET_SELECTED_PLANID_FOR_CLIENTID:
      const { planId, prevPlanId = '' } = action.payload;
      return {
        ...state,
        selectedPlan: planId,
        prevSelectedPlan: prevPlanId || '',
      };
    case SET_SELECTED_NUMBEROF_RUNS: {
      return {
        ...state,
        selectedNumberOfRuns: action.payload,
      };
    }
    case SET_SELECTED_HORIZON_TERM_FOR_CLIENT:
      return {
        ...state,
        selectedHorizonTerm: action.payload,
      };
    case SET_SELECTED_DISPLAY_TERM_FOR_CLIENT:
      return {
        ...state,
        selectedDisplayTerm: action.payload,
      };
    case SET_SELECTED_CHRONO_TAB_ITEM:
      return {
        ...state,
        selectedChronoTabItem: action.payload,
      };
    case SET_SELECTED_CHART:
      return {
        ...state,
        selectedChart: action.payload,
      };
    case SET_SELECTED_TABLE:
      return {
        ...state,
        selectedTable: action.payload,
      };

    case SET_SELECTED_DATA:
      return {
        ...state,
        selectedData: action.payload,
      };

    case SET_CLIENT_VIEW:
      return {
        ...state,
        isClientView: action.payload,
      };
    case CLEAR_SELECTED_CLIENT_PLAN_DETAILS:
      return {
        ...initialState,
      };
    case CLEAR_PUSH_PIN_CHARTS:
      return {
        ...state,
        pushPinCharts: [],
      };
    case PUSH_PIN_CHARTS:
      const { pushPinCharts } = state;
      const { pushPinReq, pushPinName } = action.payload;
      const isNewPin =
        pushPinCharts.map((pin) => pin.pushPinName).indexOf(pushPinName) === -1;
      if (!isNewPin) {
        return {
          ...state,
          pushPinCharts: pushPinCharts.map((pin) => {
            if (pin.pushPinName === pushPinName) {
              return {
                ...pin,
              };
            }
            return {
              ...pin,
            };
          }),
        };
      }
      const newPushPinCharts = pushPinCharts.map((obj) => ({
        ...obj.pushPinCharts,
      }));
      newPushPinCharts.push(pushPinReq);
      return {
        ...state,
        pushPinCharts: [...pushPinCharts, ...newPushPinCharts],
      };
    default:
      return state;
  }
}
