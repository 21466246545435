import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import {
  renderFormInputRow,
  renderFormInputRowName,
  renderFooterSubmitCancelButtons,
  renderRadioGroup,
  renderFormDateField,
  renderSelectAccountDropdown,
  renderFormSelectOption,
  DeleteConfirmation,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook

const BuildTermLifeInsuranceComponent = (props) => {
  const { state, concatContent, memberOptions, formErrors, isFormValid } =
    props;

  const moduleObject = {
    moduleTab: 'moduleInsurance',
    mainTab: 'termLife',
    subTab: 'termLifeIns',
    countVal: 'numTermLifeIns',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  return (
    <Typography>
      <div className="Select_account_main_outer">
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName, 
          'Select Policy', 
          openDeleteConfirmation,
          props,
          state,
          moduleObject
        )}
      </div>
      {/* {(isAddNew || isEditable) && (
        <div className="insured_main_outer">
          {renderFormInputRowName(
            'text',
            'tlName',
            'name',
            'Short name of policy',
            'text',
            state,
            moduleObject,
            props,
            finalExistingItems,
            true,
            formErrors,
            isAddNew,
            isEditable
          )}
        </div>
      )} */}
      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          {isAddNew ? (
            <>
              {renderRadioGroup(
                state,
                props,
                memberOptions,
                moduleObject,
                'insured',
                'Insured person',
                true, //isMandatory
                formErrors
              )}
            </>
          ) : (
            <>
              {renderFormInputRow(
                'text',
                'tlInsured',
                'insured',
                'Insured person',
                'textRadioButton',
                moduleObject,
                state,
                props,
                undefined,
                true, //isDisabled,
                true, // isMandatory
                formErrors,
                isAddNew,
                isEditable
              )}


              <div className="insured_main_outer"
                style={{ display: isEditable && !isAddNew ? 'block' : 'none' }}
              >
                {renderFormInputRowName(
                  'text',
                  'tlName',
                  'name',
                  'Short name of policy',
                  'text',
                  state,
                  moduleObject,
                  props,
                  finalExistingItems,
                  true,
                  formErrors,
                  isEditable
                )}
              </div>
            </>
          )}
          {renderFormInputRow(
            'text',
            'tlCoverage',
            'coverage',
            'Insurance Coverage Amount',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable,
            true,
            formErrors,
            isAddNew,
            isEditable
          )}
          {renderFormDateField(
            'coverageStartYr',
            'Year when coverage started or expected to start',
            'number',
            moduleObject,
            state,
            props,
            'year',
            true, //isMandatory,
            false,
            [],
            isAddNew,
            isEditable
          )}
          {renderFormInputRow(
            'text',
            'tlCoverageYrs',
            'coverageYrs',
            'Number of years of coverage',
            'number',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable,
            true,
            formErrors,
            isAddNew,
            isEditable
          )}
          {renderFormInputRow(
            'text',
            'tlPremium',
            'premium',
            'Annual Premium',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable,
            true,
            formErrors,
            isAddNew,
            isEditable
          )}
          {isAddNew && (
            <div className="insured_main_outer">
              {renderFormInputRowName(
                'text',
                'tlName',
                'name',
                'Short name of policy',
                'text',
                state,
                moduleObject,
                props,
                finalExistingItems,
                true,
                formErrors,
                isAddNew
              )}
            </div>
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}


      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </Typography>
  );
};

export default BuildTermLifeInsuranceComponent;
