import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import greenrLogo from '../images/Greenr_logo.png';
import HomeIcon from '@mui/icons-material/Home';
import history from '../history';
import { NavLink } from 'react-router-dom';
import Controls from './controls/Controls';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { updateUserTermsConsent } from '../services/dashboardSvc';
import {
  setTermsAcceptanceValue,
  setIsTermsChecked,
} from '../redux/actions/userAction';
import { getTermsAcceptanceValueSelector } from '../selectors/loginUserSelector';

const backToHome = () => {
  history.push('/');
};

const Terms = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const termsAcceptanceValue = useSelector((state) =>
  //   getTermsAcceptanceValueSelector(state)
  // );
  const data = location?.state; // Access the state data from location
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    if (termsAccepted) {
      dispatch(setIsTermsChecked(true)); // set it to true to track changes on this page, this change is used in website page
    }
  }, [termsAccepted, dispatch]);

  useEffect(() => {
    dispatch(setIsTermsChecked(false)); // set it to false on first load
  }, [dispatch]);

  const handleTermsCheckSubmit = async () => {
    dispatch(setTermsAcceptanceValue(termsAccepted));
    let page = { pathname: '/home' };
    const { state = {} } = location || {};
    const {
      afterLogin = false,
      userType = 'advisor',
      emailId = '',
      privacyAccepted,
    } = state;
    if (afterLogin) {
      if (termsAccepted) {
        //TODO: Save the user privacy consent
        const reqObj = {
          emailId: emailId,
          termsAccepted: termsAccepted,
          termsVersion: 1,
        };
        const [userTermsResponse] = await Promise.all([
          updateUserTermsConsent(reqObj),
        ]);
        if (
          userTermsResponse &&
          userTermsResponse.status === 200 &&
          privacyAccepted
        ) {
          if (userType.toLowerCase() === 'direct') {
            page = {
              pathname: '/home', // not sure what has to be here, changed from /dashboard to /client
              state: { isDirectUser: true },
            };
          } else if (userType.toLowerCase() === 'client') {
            page = {
              pathname: '/home',
              state: { isClientUser: true },
            };
          }
          history.push(page);
        } else if (!privacyAccepted) {
          page = {
            pathname: '/privacypolicy',
            state: {
              userType: userType,
              afterLogin: true,
              emailId: emailId,
              termsAccepted: termsAccepted,
            },
          };
          history.push(page);
        } else {
          history.push('/');
        }
      } else {
        history.push('/');
      }
    } else if (!privacyAccepted) {
      page = {
        pathname: '/privacypolicy',
        state: {
          userType: userType,
          afterLogin: false,
          emailId: emailId,
          termsAccepted: termsAccepted,
        },
      };
      history.push(page);
    } else {
      history.push('/');
    }
  };

  const setTermsCheckValue = (event) => {
    let { checked } = event && event.target;
    setTermsAccepted(checked);
  };

  return (
    <>
      <div className="master-main-wrapper">
        <div className="main-header">
          <nav className="navbar navbar-expand-lg navbar-light fixed-top">
            <div className="container-fluid">
              <NavLink to={'/'} className="navbar-brand" key={`home`}>
                <img src={greenrLogo} alt="" title="" />
              </NavLink>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent1"
              >
                <ul className="navbar-nav ms-auto navbar-right-top align-items-sm-center">
                  <li className="log_out">
                    <Controls.Button
                      variant="text"
                      text={<HomeIcon />}
                      onClick={backToHome}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="main-content-wrapper m-0 pb-1">
        <div className="container-fluid main-content-wrapper-inner">
          <div className="privacy_policy_outer">
            <h1>Terms and Conditions</h1>
            <h4>
              Agreement between User and{' '}
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>
            </h4>
            <p>
              Welcome to{' '}
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>
              . The{' '}
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              website (the "Site") is comprised of various web pages operated by
              Green'r Financial LLC ("Green'r").
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              is offered to you conditioned on your acceptance without
              modification of the terms, conditions, and notices contained
              herein (the "Terms"). Your use of
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              constitutes your agreement to all such Terms. Please read these
              terms carefully, and keep a copy of them for your reference.
            </p>
            <p>
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              is a Financial Planning Site.
            </p>
            <p>
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              is an easy-to-use platform for asset managers / financial advisors
              providing instant analysis of their client's retirement plan, 401K
              allocation, debt reduction, estate and lifestyle planning, and
              other related information. The platform is not intended to give
              financial advice on its own and would, instead, solely be either a
              tool that an asset manager/financial advisor would use in
              providing financial advice to their clients, or a tool that a
              client uses under the supervision / advice provided by their asset
              manager / financial advisor.
            </p>
            <p>
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              may also be accessed by direct users (who currently are not
              associated with an asset manager / financial advisor currently
              using the website) so they can input their information and assess,
              with their own judgment and their own risk, the analysis and
              results output by the website. The platform is not intended to
              give financial advice on its own and, instead, may help connecting
              the direct user with an asset manager / financial advisor in the
              network connected to the website.
            </p>
            <h5>Privacy</h5>
            <p>
              Your use of{' '}
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              is subject to Green'r's Privacy Policy. Please review our Privacy
              Policy, which also governs the Site and informs users of our data
              collection practices.
            </p>

            <h5>Electronic Communications</h5>

            <p>
              Visiting{' '}
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              or sending emails to Green'r constitutes electronic
              communications. You consent to receive electronic communications
              and you agree that all agreements, notices, disclosures and other
              communications that we provide to you electronically, via email
              and on the Site, satisfy any legal requirement that such
              communications be in writing.
            </p>

            <h5>Your Account</h5>
            <p>
              If you use this site, you are responsible for maintaining the
              confidentiality of your account and password and for restricting
              access to your computer, and you agree to accept responsibility
              for all activities that occur under your account or password. You
              may not assign or otherwise transfer your account to any other
              person or entity. You acknowledge that Green'r is not responsible
              for third party access to your account that results from theft or
              misappropriation of your account. Green'r and its associates
              reserve the right to refuse or cancel service, terminate accounts,
              or remove or edit content in our sole discretion.
            </p>

            <h5>Children Under Thirteen</h5>

            <p>
              Green'r does not permit user registration for users under the age
              of eighteen (18), and relies on user certifying that they are
              eighteen years or older. If you are under the age of eighteen
              (18), you are not allowed to use this website.
            </p>
            <p>
              Green'r collects personally identifiable information for children
              under the age of thirteen (13), where an adult client may input
              financial planning related information about their children in
              order for the system to analyze their financial plan. If you are a
              parent and you have questions regarding our data collection
              practices, please review our privacy policy which is available on
              our website
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              and further, you may contact us using the information provided at
              the end of this Agreement.
            </p>

            <h5>Cancellation/Refund Policy</h5>
            <p>
              When a user requests to be deleted from our system, the login and
              associated plan(s) information will be archived and deleted from
              the system. Subsequently, the user will not be able to login to
              the system.
            </p>

            <h5>Links to Third Party Sites/Third Party Services</h5>
            <p>
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              may contain links to other websites ("Linked Sites"). The Linked
              Sites are not under the control of Green'r and Green'r is not
              responsible for the contents of any Linked Site, including without
              limitation any link contained in a Linked Site, or any changes or
              updates to a Linked Site. Green'r is providing these links to you
              only as a convenience, and the inclusion of any link does not
              imply endorsement by Green'r of the site or any association with
              its operators.
            </p>
            <p>
              Certain services made available via{' '}
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              are delivered by third party sites and organizations. By using any
              product, service or functionality originating from the
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              domain, you hereby acknowledge and consent that Green'r may share
              such information and data with any third party with whom Green'r
              has a contractual relationship to provide the requested product,
              service or functionality on behalf of{' '}
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>
              users and customers.
            </p>

            <h5>No Unlawful or Prohibited Use/Intellectual Property</h5>
            <p>
              You are granted a non-exclusive, non-transferable, revocable
              license to access and use
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              strictly in accordance with these terms of use. As a condition of
              your use of the Site, you warrant to Green'r that you will not use
              the Site for any purpose that is unlawful or prohibited by these
              Terms. You may not use the Site in any manner which could damage,
              disable, overburden, or impair the Site or interfere with any
              other party's use and enjoyment of the Site. You may not obtain or
              attempt to obtain any materials or information through any means
              not intentionally made available or provided for through the Site.
            </p>
            <p>
              All content included as part of the Service, such as text,
              graphics, logos, images, as well as the compilation thereof, and
              any software used on the Site, is the property of Green'r or its
              suppliers and protected by copyright and other laws that protect
              intellectual property and proprietary rights. You agree to observe
              and abide by all copyright and other proprietary notices, legends
              or other restrictions contained in any such content and will not
              make any changes thereto.
            </p>
            <p>
              You will not modify, publish, transmit, reverse engineer,
              participate in the transfer or sale, create derivative works, or
              in any way exploit any of the content, in whole or in part, found
              on the Site. Green'r content is not for resale. Your use of the
              Site does not entitle you to make any unauthorized use of any
              protected content, and in particular you will not delete or alter
              any proprietary rights or attribution notices in any content. You
              will use protected content solely for your personal use, and will
              make no other use of the content without the express written
              permission of Green'r and the copyright owner. You agree that you
              do not acquire any ownership rights in any protected content. We
              do not grant you any licenses, express or implied, to the
              intellectual property of Green'r or our licensors except as
              expressly authorized by these Terms.
            </p>

            <h5>Third Party Accounts</h5>
            <p>
              You will be able to connect your Green'r account to third party
              accounts. By connecting your Green'r account to your third party
              account, you acknowledge and agree that you are consenting to the
              continuous release of information about you to others (in
              accordance with your privacy settings on those third party sites).
              If you do not want information about you to be shared in this
              manner, do not use this feature.
            </p>
            <h5>International Users</h5>
            <p>
              The Service is controlled, operated and administered by Green'r
              from our offices within the USA. If you access the Service from a
              location outside the USA, you are responsible for compliance with
              all local laws. You agree that you will not use the Green'r
              Content accessed through
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              in any country or in any manner prohibited by any applicable laws,
              restrictions or regulations.
            </p>

            <h5>Indemnification</h5>
            <p>
              You agree to indemnify, defend and hold harmless Green'r, its
              officers, directors, employees, agents and third parties, for any
              losses, costs, liabilities and expenses (including reasonable
              attorney's fees) relating to or arising out of your use of or
              inability to use the Site or services, any user postings made by
              you, your violation of any terms of this Agreement or your
              violation of any rights of a third party, or your violation of any
              applicable laws, rules or regulations. Green'r reserves the right,
              at its own cost, to assume the exclusive defense and control of
              any matter otherwise subject to indemnification by you, in which
              event you will fully cooperate with Green'r in asserting any
              available defenses.
            </p>

            <h5>Arbitration</h5>
            <p>
              In the event the parties are not able to resolve any dispute
              between them arising out of or concerning these Terms and
              Conditions, or any provisions hereof, whether in contract, tort,
              or otherwise at law or in equity for damages or any other relief,
              then such dispute shall be resolved only by final and binding
              arbitration pursuant to the Federal Arbitration Act, conducted by
              a single neutral arbitrator and administered by the American
              Arbitration Association, or a similar arbitration service selected
              by the parties, in a location mutually agreed upon by the parties.
              The arbitrator's award shall be final, and judgment may be entered
              upon it in any court having jurisdiction. In the event that any
              legal or equitable action, proceeding or arbitration arises out of
              or concerns these Terms and Conditions, the prevailing party shall
              be entitled to recover its costs and reasonable attorney's fees.
              The parties agree to arbitrate all disputes and claims in regards
              to these Terms and Conditions or any disputes arising as a result
              of these Terms and Conditions, whether directly or indirectly,
              including Tort claims that are a result of these Terms and
              Conditions. The parties agree that the Federal Arbitration Act
              governs the interpretation and enforcement of this provision. The
              entire dispute, including the scope and enforceability of this
              arbitration provision shall be determined by the Arbitrator. This
              arbitration provision shall survive the termination of these Terms
              and Conditions.
            </p>

            <h5>Class Action Waiver</h5>
            <p>
              Any arbitration under these Terms and Conditions will take place
              on an individual basis; class arbitrations and
              class/representative/collective actions are not permitted. THE
              PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY
              IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
              MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
              PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL
              ACTION AGAINST THE OTHER. Further, unless both you and Green'r
              agree otherwise, the arbitrator may not consolidate more than one
              person's claims, and may not otherwise preside over any form of a
              representative or class proceeding.
            </p>

            <h5>Liability Disclaimer</h5>
            <p>
              THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
              AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
              TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
              INFORMATION HEREIN. GREEN'R FINANCIAL LLC AND/OR ITS SUPPLIERS MAY
              MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
            </p>

            <p>
              GREEN'R FINANCIAL LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS
              ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND
              ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
              RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE
              MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
              SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS
              IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. GREEN'R FINANCIAL
              LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND
              CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS,
              SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR
              CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE AND NON-INFRINGEMENT.
            </p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              SHALL GREEN'R FINANCIAL LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY
              DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
              DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION,
              DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY
              WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE
              DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE
              PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
              INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
              OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF
              THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
              LIABILITY OR OTHERWISE, EVEN IF GREEN'R FINANCIAL LLC OR ANY OF
              ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
              BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
              THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED
              WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE,
              YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
            </p>

            <h5>Termination/Access Restriction</h5>
            <p>
              Green'r reserves the right, in its sole discretion, to terminate
              your access to the Site and the related services or any portion
              thereof at any time, without notice. To the maximum extent
              permitted by law, this agreement is governed by the laws of the
              State of Delaware and you hereby consent to the exclusive
              jurisdiction and venue of courts in Delaware in all disputes
              arising out of or relating to the use of the Site. Use of the Site
              is unauthorized in any jurisdiction that does not give effect to
              all provisions of these Terms, including, without limitation, this
              section.
            </p>
            <p>
              You agree that no joint venture, partnership, employment, or
              agency relationship exists between you and Green'r as a result of
              this agreement or use of the Site. Green'r's performance of this
              agreement is subject to existing laws and legal process, and
              nothing contained in this agreement is in derogation of Green'r's
              right to comply with governmental, court and law enforcement
              requests or requirements relating to your use of the Site or
              information provided to or gathered by Green'r with respect to
              such use. If any part of this agreement is determined to be
              invalid or unenforceable pursuant to applicable law including, but
              not limited to, the warranty disclaimers and liability limitations
              set forth above, then the invalid or unenforceable provision will
              be deemed superseded by a valid, enforceable provision that most
              closely matches the intent of the original provision and the
              remainder of the agreement shall continue in effect.
            </p>
            <p>
              Unless otherwise specified herein, this agreement constitutes the
              entire agreement between the user and Green'r with respect to the
              Site and it supersedes all prior or contemporaneous communications
              and proposals, whether electronic, oral or written, between the
              user and Green'r with respect to the Site. A printed version of
              this agreement and of any notice given in electronic form shall be
              admissible in judicial or administrative proceedings based upon or
              relating to this agreement to the same extent and subject to the
              same conditions as other business documents and records originally
              generated and maintained in printed form. It is the express wish
              to the parties that this agreement and all related documents be
              written in English.
            </p>

            <h5>Changes to Terms</h5>
            <p>
              Green'r reserves the right, in its sole discretion, to change the
              Terms under which 
              <a href="https://greenrfinancial.com">
                https://greenrfinancial.com
              </a>{' '}
              is offered. The most current version of the Terms will supersede
              all previous versions. Green'r encourages you to periodically
              review the Terms to stay informed of our updates.
            </p>

            <h5>Contact Us</h5>
            <p>
              Green'r welcomes your questions or comments regarding the Terms:
            </p>

            <h5>
              Email Address:{' '}
              <a href="mailto:info@greenrfinancial.com">
                info@greenrfinancial.com
              </a>
            </h5>

            {data && (
              <>
                <Grid container className="signup-form-grid" spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="termsAccepted"
                          checked={termsAccepted}
                          value={termsAccepted}
                        />
                      }
                      label={
                        'I agree to and accept the Terms & Conditions in this document, and I certify I am 18 years or older than 18 years'
                      }
                      onChange={(event) =>
                        setTermsCheckValue(event, 'termsAccepted')
                      }
                    />
                  </Grid>
                </Grid>
                <button
                  type="button"
                  className={
                    termsAccepted
                      ? 'common-button'
                      : 'common-button secondary-button'
                  }
                  disabled={!termsAccepted}
                  onClick={() => handleTermsCheckSubmit()}
                >
                  Continue
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
