import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import Alert from '@mui/material/Alert';
import { transformEmergencyFundsBackToUIType } from '../../../helpers/BuildHelperComponentV3';

const SettingsEmergencyFunds = forwardRef((props, ref) => {
  const { localPlanDetailsByPlanId, setLocalPlanDetailsByPlanId } = props;

  const { moduleSettings: { emergencyFunds = {} } = {} } =
    localPlanDetailsByPlanId;

  const [emergencyFundsDetails, setEmergencyFundsDetails] = useState({
    emCashReqYrs: '',
    emCashPercentForDiv: '',
    divRate: '',
  });

  useEffect(() => {
    if (emergencyFunds && Object.keys(emergencyFunds).length > 0) {
      let tempEmergencyFunds =
        transformEmergencyFundsBackToUIType(emergencyFunds);
      let { emCashPercentForDiv, divRate } = tempEmergencyFunds;

      setEmergencyFundsDetails({
        ...emergencyFundsDetails,
        emCashReqYrs: emergencyFunds.emCashReqYrs,
        emCashPercentForDiv,
        divRate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPlanDetailsByPlanId]);

  const handleFormFieldChange = (event, name, valueType = 'percentage') => {
    let fieldValue = event.target.value;
    if (valueType === 'percentage') {
      if (fieldValue.toString().includes('%')) {
        let percentFieldValue = fieldValue.split('%')[0];
        if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
          fieldValue = percentFieldValue;
        }
      }
    } else if (valueType === 'number') {
      if (
        fieldValue !== '' &&
        fieldValue !== null &&
        fieldValue !== undefined
      ) {
        if (isNaN(fieldValue)) {
          fieldValue = 0;
        }
      }
    }
    let tempModelObj = {
      ...emergencyFundsDetails,
      [name]: fieldValue,
    };

    setEmergencyFundsDetails(tempModelObj);
  };

  useImperativeHandle(ref, () => ({
    getSettingsEmergencyFundsState() {
      const updatedEmergencyFundsDetails = Object.keys(
        emergencyFundsDetails
      ).reduce((acc, curr) => {
        const fieldValue = emergencyFundsDetails[curr];
        let valueToAPI = fieldValue;
        if (fieldValue.toString().includes('%')) {
          const percentValue = fieldValue.split('%')[0];
          if (!isNaN(percentValue)) {
            valueToAPI = parseFloat(parseFloat(percentValue) / 100);
          }
        }
        return {
          ...acc,
          [curr]: valueToAPI,
        };
      }, {});
      return updatedEmergencyFundsDetails;
    },
  }));

  const handleBlur = (event, name, valueType = 'percentage') => {
    let { value } = event && event.target;
    if (valueType === 'percentage') {
      let percentFieldValue = '';
      if (value !== null && value !== undefined && value !== '') {
        if (value.toString().includes('%')) {
          percentFieldValue = value.split('%')[0];
          if (
            percentFieldValue !== '' &&
            !isNaN(percentFieldValue) &&
            Number(percentFieldValue) >= 0
          ) {
            percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
            value = `${percentFieldValue}%`;
          } else {
            value = `${0}%`;
          }
        } else if (!isNaN(value) && Number(value) >= 0) {
          percentFieldValue = parseFloat(value).toFixed(2);
          value = `${percentFieldValue}%`;
        } else {
          value = `${0}%`;
        }
      } else {
        value = `${0}%`;
      }
    }
    if (valueType === 'number') {
      if (value !== '' && value !== null && value !== undefined) {
        if (!isNaN(value) && Number(value) >= 0) {
          value = Number(value);
        } else {
          value = 0;
        }
      } else {
        value = 0;
      }
    }

    const tempEmergencyFunds = {
      ...emergencyFundsDetails,
      [name]: value,
    };
    setEmergencyFundsDetails(tempEmergencyFunds);
  };

  const renderInputRow = (name, label, type) => {
    return (
      <div className="row align-items-center">
        <div className={`col-lg-9 col-sm-8 col-6 insured_content_outer`}>
          <p>{label}</p>
        </div>
        <div className={`col-lg-3 col-sm-4 col-6 insured_radio_group`}>
          <input
            type="text"
            id="tlDeficitAsPercentOfExpForPropLiquidation"
            name={name}
            value={emergencyFundsDetails[name]}
            className={'custom-input'} // Apply the appropriate class
            onChange={(event) => handleFormFieldChange(event, name, type)}
            onBlur={(event) => handleBlur(event, name, type)}
            placeholder=""
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="insured_main_outer">
        {renderInputRow(
          'emCashReqYrs',
          'How many years worth of expenses would you like to keep as emergency funds?',
          'number'
        )}
        {renderInputRow(
          'emCashPercentForDiv',
          'What % of emergency funds would you like to connvert into additional dividend portfolio when you retire?',
          'percentage'
        )}
        {renderInputRow(
          'divRate',
          'What is your projected dividend rate for such connverted funds?',
          'percentage'
        )}
      </div>

      <hr />

      <div className="insured_main_outer">
        <Alert severity="info">
          Emergency funds account(s) will be captured in Investments / Markets
        </Alert>
      </div>
    </>
  );
});

export default SettingsEmergencyFunds;
