// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-container {
  margin-top: 3em;
  margin-left: 6em;
}
.admin-container .tabs-container {
  padding: 8px;
  background-color: white;
}
.admin-container .tabs-container .MuiTabs-root {
  border-bottom: 1px solid #e8e8e8;
}
.admin-container .tabs-container .MuiTab-root {
  min-width: 72px;
  font-weight: 400;
  margin-right: 32px;
}
.admin-container .tabs-container .MuiTab-root:focus {
  outline: none;
  color: #288693;
}
.admin-container .tabs-container .MuiTabs-indicator {
  background-color: #288693;
}
.admin-container .tabs-container .Mui-selected {
  color: #288693;
  font-weight: bold;
}
.admin-container .tab-view-container {
  height: 600px;
  margin-left: 8px;
}
.admin-container .tab-view-container .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}
.admin-container .tab-view-container .MuiDataGrid-root .action-btn {
  color: white;
}
.admin-container .tab-view-container .MuiDataGrid-root .action-btn .MuiSvgIcon-root {
  margin-right: 4px;
}
.admin-container .tab-view-container .MuiDataGrid-root .approve-btn .MuiSvgIcon-root {
  color: green;
}
.admin-container .tab-view-container .MuiDataGrid-root .reject-btn .MuiSvgIcon-root {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/styles/adminDashboard.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF;AAAE;EACE,YAAA;EAEA,uBAAA;AACJ;AAAI;EACE,gCAAA;AAEN;AAAI;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAEN;AADM;EACE,aAAA;EACA,cAAA;AAGR;AACI;EACE,yBAAA;AACN;AACI;EACE,cAAA;EACA,iBAAA;AACN;AAEE;EACE,aAAA;EACA,gBAAA;AAAJ;AAGM;EACE,iBAAA;AADR;AAGM;EACE,YAAA;AADR;AAEQ;EACE,iBAAA;AAAV;AAIQ;EACE,YAAA;AAFV;AAMQ;EACE,UAAA;AAJV","sourcesContent":[".admin-container {\r\n  margin-top: 3em;\r\n  margin-left: 6em;\r\n  .tabs-container {\r\n    padding: 8px;\r\n    // width: 500px;\r\n    background-color: white;\r\n    .MuiTabs-root {\r\n      border-bottom: 1px solid #e8e8e8;\r\n    }\r\n    .MuiTab-root {\r\n      min-width: 72px;\r\n      font-weight: 400;\r\n      margin-right: 32px;\r\n      &:focus {\r\n        outline: none;\r\n        color: #288693;\r\n      }\r\n    }\r\n\r\n    .MuiTabs-indicator {\r\n      background-color: #288693;\r\n    }\r\n    .Mui-selected {\r\n      color: #288693;\r\n      font-weight: bold;\r\n    }\r\n  }\r\n  .tab-view-container {\r\n    height: 600px;\r\n    margin-left: 8px;\r\n\r\n    .MuiDataGrid-root {\r\n      .MuiDataGrid-columnHeaderTitle {\r\n        font-weight: bold;\r\n      }\r\n      .action-btn {\r\n        color: white;\r\n        .MuiSvgIcon-root {\r\n          margin-right: 4px;\r\n        }\r\n      }\r\n      .approve-btn {\r\n        .MuiSvgIcon-root {\r\n          color: green;\r\n        }\r\n      }\r\n      .reject-btn {\r\n        .MuiSvgIcon-root {\r\n          color: red;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
