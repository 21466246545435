// src/analytics.js
import ReactGA from 'react-ga4';
import { GA_TRACKING_ID } from './services/CONSTANTS';

//const GA_TRACKING_ID = 'G-1NYZHBCNJ1'; // Replace with your GA4 tracking ID

export const initializeAnalytics = () => {
    ReactGA.initialize(GA_TRACKING_ID);
};

export const logPageView = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

export const logEvent = (category, action, label = '', value = 0) => {
    ReactGA.event({
        category,
        action,
        label,
        value
    });
};

