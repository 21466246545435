import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  renderDoubleInputRow,
  transformAlertsBackToUIType,
} from '../../../helpers/BuildHelperComponentV3';

const SettingsAlertComponentV4 = forwardRef((props, ref) => {
  const { planDetailsByPlanId, updatePlanDetailsInRedux } = props;

  const { moduleSettings: { alerts = {} } = {} } = planDetailsByPlanId;
  const [alertsDetails, setAlertsDetails] = useState(alerts);

  useImperativeHandle(ref, () => ({
    getSettingsAlertDetails() {
      return Object.keys(alertsDetails).reduce((acc, curr) => {
        const value = alertsDetails[curr];
        let valueToApi = value;
        if (value !== null && value !== undefined && value !== '') {
          if (isNaN(value) && value.includes('%')) {
            const percentValue = value.split('%')[0];
            if (!isNaN(percentValue)) {
              valueToApi = parseFloat(parseFloat(percentValue) / 100);
            } else {
              valueToApi = 0;
            }
          } else if (isNaN(value) && value.includes('$')) {
            let dollarValue = value.split('$')[1];
            dollarValue = dollarValue.replace(/,/g, '');
            if (!isNaN(dollarValue)) {
              valueToApi = Number(dollarValue);
            } else {
              valueToApi = 0;
            }
          } else if (!isNaN(value)) {
            valueToApi = Number(value);
          }
        }
        return {
          ...acc,
          [curr]: valueToApi,
        };
      }, {});
    },
  }));

  useEffect(() => {
    if (alerts && Object.keys(alerts).length > 0) {
      let tempAlerts = transformAlertsBackToUIType(alerts);
      setAlertsDetails(tempAlerts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetailsByPlanId]);

  return (
    <>
      <div className="insured_main_outer">
        <div className="row align-items-center personal_div_first_row">
          <div className="col-lg-6 col-12">
            <p>
              <strong>Alert Type</strong>
            </p>
          </div>
          <div className="col-lg-3 col-6">
            <p style={{ textAlign: 'center' }}>
              <strong style={{ fontWeight: 'bold' }}>Alert 1</strong>
            </p>
          </div>
          <div className="col-lg-3 col-6">
            <p style={{ textAlign: 'center' }}>
              <strong style={{ fontWeight: 'bold' }}>Alert 2</strong>
            </p>
          </div>
        </div>
      </div>
      <hr style={{ marginTop: '0px' }} />
      <div className="insured_main_outer">
        {renderDoubleInputRow(
          'text',
          'tldebtRateYellow',
          'debtRateYellowThreshold',
          'tldebtRateRed',
          'debtRateRedThreshold',
          'Debt rate',
          'percentage',
          alertsDetails,
          'alerts',
          planDetailsByPlanId,
          setAlertsDetails,
          'moduleSettings',
          setAlertsDetails
        )}
        {renderDoubleInputRow(
          'text',
          'tlInvReturnYellowHigh',
          'invReturnYellowHigh',
          'tlInvReturnRed',
          'invReturnRedHigh',
          'Investment return (upper bracket)',
          'percentage',
          alertsDetails,
          'alerts',
          planDetailsByPlanId,
          setAlertsDetails,
          'moduleSettings',
          setAlertsDetails
        )}
        {renderDoubleInputRow(
          'text',
          'tlInvReturnYellowLow',
          'invReturnYellowLow',
          'tlInvReturnRedLow',
          'invReturnRedLow',
          'Investment return (lower bracket)',
          'percentage',
          alertsDetails,
          'alerts',
          planDetailsByPlanId,
          setAlertsDetails,
          'moduleSettings',
          setAlertsDetails
        )}
        {renderDoubleInputRow(
          'text',
          'tlInsuranceYellow',
          'insuranceYellowThreshold',
          'tlInsuranceRed',
          'insuranceRedThreshold',
          'Insurance sufficiency',
          'percentage',
          alertsDetails,
          'alerts',
          planDetailsByPlanId,
          setAlertsDetails,
          'moduleSettings',
          setAlertsDetails
        )}
      </div>
    </>
  );
});

export default SettingsAlertComponentV4;
