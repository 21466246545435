import React from 'react';
import Controls from './controls/Controls';

export const WithdrawalChoicesSolutionsComp = (props) => {
  const { localPlanDetailsByPlanId, setLocalPlanDetailsByPlanId } = props;

  const { moduleEstate = {} } = localPlanDetailsByPlanId;

  const { rothChoices = {} } = moduleEstate;

  const { withdrawRothFirst } = rothChoices;

  const withDrawRothFirstOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const handleSelectChange = (event, name) => {
    const { value } = event.target;
    let updatedPlanDetails = {
      ...localPlanDetailsByPlanId,
      moduleEstate: {
        ...moduleEstate,
        rothChoices: {
          ...rothChoices,
          withdrawRothFirst: value,
        },
      },
    };
    setLocalPlanDetailsByPlanId(updatedPlanDetails);
  };

  const renderSelectOption = (type, idName, name, label, options) => {
    return (
      <div className="row align-items-center" key={idName}>
        <div className={`col-lg-9 col-sm-8 col-6 insured_content_outer`}>
          <p>{label}</p>
        </div>
        <div className={`col-lg-3 col-sm-4 col-6 insured_radio_group`}>
          <Controls.Select
            name={idName}
            value={withdrawRothFirst}
            options={options.map((c) => {
              return {
                value: c.value,
                label: c.label,
              };
            })}
            onChange={(event, index) => handleSelectChange(event, name)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="insured_main_outer">
        <div className="row align-items-center">
          <div className="col-lg-9 col-sm-9 col-8 insured_content_outer">
            <p>
              <strong>Retirement Fund Withdrawals</strong>
            </p>
          </div>
        </div>
        {renderSelectOption(
          'text',
          'ddlMemOneWithdrawRothFirst',
          'withdrawRothFirst',
          'When withdrawing funds from Retirement accounts, would you like to withdraw first from ROTH?',
          withDrawRothFirstOptions
        )}
      </div>
    </>
  );
};

export default WithdrawalChoicesSolutionsComp;
