import React, { Component, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { USER_ANALYTICS, USER_MANAGEMENT } from '../../constants/AdminHome';
import '../../styles/advisorhome.scss';
import Controls from '../controls/Controls';
import {
  getSelectToBeApprovedUsers,
  approveSelectedUser,
} from '../../services/adminUserSvc';

const UserAnalyticsSection = () => (
  <>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mobile_card justify-content-between">
      <div className={`card home_main_card_outer one mb-4 active`}>
        <div className="card-body assets_under_management_inner">
          <div className="Under_Management d-md-flex">
            <div>
              <div className="Under_Management_content d-md-flex">
                <div className="Under_Management_content_left">
                  <p>User Analytics</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="card h-100">
        <div className="card-body">
          <div className="chart_div"></div>
        </div>
      </div>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="card h-100">
        <div className="card-body"></div>
      </div>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="card h-100 mb-0">
        <div className="card-body"></div>
      </div>
    </div>
  </>
);

const UserManagementSection = (
  selectedUserToApprove,
  usersToBeApprovedList,
  onSelectedUserChange,
  approveSelectedUserEvent
) => {
  return (
    <div className="card-body assets_under_management_inner">
      <h5 className="text-white">User Management</h5>
      <div className="Under_Management d-md-flex"></div>
      <div className="select_client_outer">
        <form className="">
          <div className="select_client_outer_form_row">
            <div className="client client-review-dd">
              <div className="Select_Client">
                <Controls.Select
                  className="js-example-basic-single"
                  name="Select_User"
                  id="Select_User"
                  placeholder="Select User"
                  value={selectedUserToApprove}
                  onChange={(event) => onSelectedUserChange(event)}
                  options={usersToBeApprovedList.map((c) => {
                    return {
                      value: c.emailId,
                      label: c.name,
                    };
                  })}
                />
              </div>
            </div>
            <div className="plan client-review-dd">
              <button
                type="button"
                className="common-button"
                onClick={approveSelectedUserEvent}
              >
                {/* <img src={add} alt="" title="" /> */}
                Approve
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

class AdminHome extends Component {
  state = {
    activeCard: USER_MANAGEMENT,
    selectedUserToApprove: '',
    usersToBeApprovedList: [],
    openSnackbar: true,
    isApiFailed: false,
    successSnackBarMsg: '',
  };

  componentDidMount() {
    const { activeCard } = this.state;
    if (activeCard === USER_MANAGEMENT) {
      this.getSelectedUsersToBeApproved();
    }
  }

  getSelectedUsersToBeApproved = async () => {
    try {
      const { data, status } = await getSelectToBeApprovedUsers();
      if (status === 200) {
        this.setState({
          usersToBeApprovedList: data,
        });
      } else {
        this.setIsAPIFailedStatus(true);
      }
    } catch (e) {
      this.setIsAPIFailedStatus(true);
    }
  };

  onSelectedUserChange = (event) => {
    const { value: selectedUserToApprove } = event.target;
    this.setState({
      selectedUserToApprove,
    });
  };

  approveSelectedUserEvent = async () => {
    const { selectedUserToApprove } = this.state;
    try {
      const { data, status } = await approveSelectedUser(
        selectedUserToApprove,
        true
      );
      if (status === 200) {
        this.setState(
          {
            openSnackbar: true,
            successSnackBarMsg: data,
          },
          () => this.getSelectedUsersToBeApproved()
        );
      } else {
        this.setIsAPIFailedStatus(true);
      }
    } catch (e) {
      this.setIsAPIFailedStatus(true);
    }
  };

  setIsAPIFailedStatus = (isApiFailed) => {
    this.setState({
      isApiFailed,
    });
  };

  handleClose = () => {
    this.setState({
      openSnackbar: false,
      successSnackBarMsg: '',
    });
  };

  render() {
    const {
      activeCard,
      selectedUserToApprove,
      usersToBeApprovedList,
      openSnackbar,
      isApiFailed,
      successSnackBarMsg,
    } = this.state;
    return (
      <div className="main-content-wrapper">
        <div className="container-fluid main-content-wrapper-inner">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 desktop_card d-flex justify-content-between">
              <div
                className={`card home_main_card_outer one mb-4 ${
                  activeCard === USER_MANAGEMENT ? `active` : ``
                }`}
              >
                {UserManagementSection(
                  selectedUserToApprove,
                  usersToBeApprovedList,
                  this.onSelectedUserChange,
                  this.approveSelectedUserEvent
                )}
              </div>
              <div
                className={`card home_main_card_outer three mb-4 ${
                  activeCard === 'clients' ? `active` : ``
                }`}
                onClick={() => {
                  this.setState({
                    activeCard: 'clients',
                    selectedMeetingId: '',
                  });
                }}
              >
                <div className="card-body client_contact_recency_inner">
                  <h5 className="text-white">User Analytics</h5>
                  <div className="Under_Management d-md-flex">
                    <div className="Under_Management_content d-flex"></div>
                  </div>
                  <div className="select_client_outer"></div>
                  <div className="select_client_outer">
                    <form className="">
                      <div className="select_client_outer_form_row">
                        <div className="client client-review-dd"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div
                className={`card home_main_card_outer two mb-4 ${
                  activeCard === 'meetings' ? `active` : ``
                }`}
              >
                <div className="card-body assets_under_management_inner"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {activeCard === USER_ANALYTICS && <UserAnalyticsSection />}
          </div>
        </div>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={this.handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {!isApiFailed
              ? successSnackBarMsg
              : 'Something went wrong, please try again !'}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default AdminHome;
