import React from 'react';
import add from '../../images/add.svg';
import Controls from '../controls/Controls';

const AdvisorReviewsFirstRowSection = (props) => {
  const {
    activeCard, 
    setSelectedAdvisorId,
    selectedAdvisorId, 
    advsiorStateOptions, 
    handleAdvisorOnboardingModalOpen,
    allAdvisorsList,
    setActiveCardState,
    setAdvisorSelectedState,
    advisorSelectedState,
  } = props;

  const handleAdvisorStateOnChange = (event) => {
    const { value } = event.target;
    setAdvisorSelectedState(value);
  };

  return (
  <>
    <div className="col-lg-12 col-md-12 col-sm-12 mobile_card justify-content-between">
      <div
        className={`card home_main_card_outer three mb-4 ${
          activeCard === 'advisor' ? `active` : ``
        }`}
        onClick={() => setActiveCardState('advisor')}
      >
        <div className="card-body client_contact_recency_inner">
          <div className="Select_state_outer">
            <h6>State</h6>
            <Controls.Select
              options={advsiorStateOptions || []}
              className="js-example-basic-single"
              // placeholder="Select State"
              value={advisorSelectedState}
              onChange={handleAdvisorStateOnChange}
            />
          </div>
          <div className="select_client_outer">
            <form className="">
              <div className="select_client_outer_form_row">
                <div className="client client-review-dd">
                  <div className="Select_Client">
                    <Controls.Select
                      className="js-example-basic-single"
                      name="Select_Client"
                      id="Select_Client"
                      placeholder="Select Advisor"
                      value={selectedAdvisorId}
                      onChange={(event) =>
                        setSelectedAdvisorId(event.target.value)
                      }
                      options={allAdvisorsList.map((id) => {
                        return {
                          value: id,
                          label: id,
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="plan client-review-dd advisor_add_btn_outer">
                  <button
                    type="button"
                    className="common-button"
                    onClick={() => handleAdvisorOnboardingModalOpen(true)}
                  >
                    <img src={add} alt="" title="" />
                    Advisor
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </>
);

};

export default AdvisorReviewsFirstRowSection;
