import React, { Component } from 'react';
import drilldown from 'highcharts/modules/drilldown.js';
import HighchartsReact from 'highcharts-react-official';
import { isEqual } from 'lodash';
import { Grid } from '@material-ui/core';
import {
  setMainChartOptions,
  setBulletSeriesOptions,
  setSubChartOptions,
  setPieChartsData,
  setMainChartOptionsArearange,
  setMainChartOptionsLine,
  setTimelineChartOptions,
  setMeterChartOptions,
  setBubbleChartOptions,
  setLollipopChartOptions,
  setSpiderWebChartOptions,
} from './shared/ChartData';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Highcharts from 'highcharts/highstock';
import highchartsMore from 'highcharts/highcharts-more';
import ChartContainer from './ChartContainer';
import Button from '@mui/material/Button';
import PushPinIcon from '@mui/icons-material/PushPin';
import { orange, yellow, green, red, grey } from '@mui/material/colors';
import TimelineChartContainer from './TimelineChartContainer';
import timeline from 'highcharts/modules/timeline.js';
timeline(Highcharts);
highchartsMore(Highcharts);
drilldown(Highcharts);

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: '28px  !important',
  height: '16px  !important',
  padding: '0px  !important',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '12px  !important',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    color: '#fff',
    padding: '2px  !important',
    position: 'absolute !important',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#6ccea6' : '#225ea8',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: '12px  !important',
    height: '12px  !important',
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'grey',
    boxSizing: 'border-box',
  },
}));
export class MainChartsContainer extends Component {
  render() {
    const { options, selectedChart, index, colSize, chartType } = this.props;
    const selectedChartId = index ? selectedChart + '_' + index : selectedChart;
    return (
      <HighchartsReact
        id={selectedChartId}
        highcharts={Highcharts}
        options={options}
        oneToOne={true}
      />
    );
  }
}

class NewChartsContainer extends Component {
  state = {
    mainChartData: {},
    auxChartData: {},
    timelineChartData: {},
    meterChartData: {},
    nowChartsData: {},
    nearTermData: {},
    lollypopChartData: {},
    spiderWebChartData: {},
    mediumTermData: {},
    bulletChartData: {},
    bubbleChartData: {},
    longTermData: {},
    isPieAtVariousAges: false,
    isPieAtDrillDownLevel: false,
    isPieChartsAvailable: false,
    isDrillDown: false,
    canDataRender: false,
    drillDownPieChartsData: {},
    isShowRightAxis: false,
    isRightAxisAvailable: false,
  };

  drillupCallback = () => {
    this.setState({
      isDrillDown: false,
      canDataRender: false,
      drillDownPieChartsData: {},
    });
  };

  drilldownCallback = (e) => {
    const { selectedChart, chartsData } = this.props;
    const totalOrSurplusData =
      chartsData &&
      chartsData.timeSeries &&
      chartsData.timeSeries.main &&
      chartsData.timeSeries.main.yaxis &&
      chartsData.timeSeries.main.yaxis.length > 0 &&
      chartsData.timeSeries.main.yaxis.filter((f) => f.rightAxis);
    let totalOrSurplusDataSeriesName = '';
    if (totalOrSurplusData.length > 0) {
      totalOrSurplusDataSeriesName = totalOrSurplusData[0].seriesName;
    }
    if (
      e &&
      e.seriesOptions &&
      chartsData &&
      chartsData.timeSeries &&
      chartsData.timeSeries.main
    ) {
      const yAxisLeftLabel = chartsData.timeSeries.main.yaxisLeftLabel
        ? chartsData.timeSeries.main.yaxisLeftLabel
        : '';
      const chartName = chartsData.timeSeries.main.chartName
        ? chartsData.timeSeries.main.chartName
        : '';
      const drillDownPieChartsData = setPieChartsData(
        e,
        chartName,
        totalOrSurplusDataSeriesName,
        yAxisLeftLabel
      );
      this.setState({
        isDrillDown: true,
        isPieAtVariousAges: false,
        drillDownPieChartsData,
      });
    }
  };

  generateSubChartsData = () => {
    const { chartsData } = this.props;
    if (
      chartsData &&
      chartsData.timeSeries &&
      chartsData.timeSeries.main &&
      chartsData.timeSeries.sub &&
      chartsData.timeSeries.sub.length > 0
    ) {
      const yAxisLeftLabel =
        (chartsData.timeSeries.main.yaxisLeftLabel &&
          chartsData.timeSeries.main.yaxisLeftLabel !== '' &&
          chartsData.timeSeries.main.yaxisLeftLabel) ||
        '';
      const nowChartsData = setSubChartOptions(
        chartsData.timeSeries.sub[0],
        'LTS', // to distinguish cta pie chart and lifetime simulation pies
        yAxisLeftLabel
      );
      const nearTermData = setSubChartOptions(
        chartsData.timeSeries.sub[1],
        'LTS',
        yAxisLeftLabel
      );
      const mediumTermData = setSubChartOptions(
        chartsData.timeSeries.sub[2],
        'LTS',
        yAxisLeftLabel
      );
      const longTermData = setSubChartOptions(
        chartsData.timeSeries.sub[3],
        'LTS',
        yAxisLeftLabel
      );
      this.setState({
        nowChartsData,
        nearTermData,
        mediumTermData,
        longTermData,
      });
    }
  };

  isPieChartsAvailable = () => {
    const { chartsData } = this.props;
    if (
      chartsData &&
      chartsData.timeSeries &&
      chartsData.timeSeries.sub &&
      chartsData.timeSeries.sub.length > 0 &&
      chartsData.timeSeries.numSubCharts > 0
    ) {
      this.setState({ isPieChartsAvailable: true });
    } else {
      this.setState({ isPieChartsAvailable: false });
    }
  };

  isRightAxisAvailable = (type) => {
    const { chartsData } = this.props;
    const rightAxisData =
      chartsData &&
      chartsData.timeSeries &&
      chartsData.timeSeries[type] &&
      chartsData.timeSeries[type].yaxis &&
      chartsData.timeSeries[type].yaxis.length > 0 &&
      chartsData.timeSeries[type].yaxis.filter((f) => f.rightAxis)[0];
    if (rightAxisData && Object.keys(rightAxisData).length > 0) {
      this.setState({ isRightAxisAvailable: true });
    } else {
      this.setState({ isRightAxisAvailable: false });
    }
  };

  setTimeseriesChartOptions = (isShowRightAxis, type) => {
    const { chartsData } = this.props;
    const chartType =
      (chartsData &&
        chartsData.timeSeries &&
        chartsData.timeSeries[type] &&
        chartsData.timeSeries[type].leftChartStyle) ||
      '';
    let timeseriesChartData = {};
    if (chartType) {
      if (chartType.toLowerCase() === 'stacked') {
        timeseriesChartData = setMainChartOptions(
          chartsData.timeSeries[type],
          this.drillupCallback,
          this.drilldownCallback,
          isShowRightAxis
        );
      } else if (chartType.toLowerCase() === 'line') {
        timeseriesChartData = setMainChartOptionsLine(
          chartsData.timeSeries[type]
        );
      } else {
        timeseriesChartData = setMainChartOptionsArearange(
          chartsData.timeSeries[type]
        );
      }
    }
    if (type === 'main') {
      this.setState(
        {
          mainChartData: timeseriesChartData,
        },
        () => {
          this.isPieChartsAvailable();
          this.isRightAxisAvailable('main');
        }
      );
    } else {
      this.setState(
        {
          auxChartData: timeseriesChartData,
        },
        () => {
          this.isPieChartsAvailable();
          this.isRightAxisAvailable('aux');
        }
      );
    }
  };

  getColor = (color) => {
    switch (color && color.toLowerCase()) {
      case 'green':
        return '#00A36C';
      case 'lightgreen':
        return '#90EE90';
      case 'red':
        return '#C70039';
      case 'orange':
        return '#FFC300';
      case 'yellow':
        return yellow[600];
      default:
        return grey[600];
    }
  };

  generateTimelineChartData = (milestones, heading) => {
    let chartOptions = milestones.filter(
      (m) => m.year !== 0 && m.year !== null
    );
    chartOptions = chartOptions.sort((a, b) => a.year - b.year);
    chartOptions = chartOptions.map((m, index) => {
      return {
        name: m.name + '<br/>' + m.year + `${m.desc}`,
        color: this.getColor(m.healthFlag ? m.healthFlag : 'grey'),
        dataLabels: {
          color: '#000000',
          backgroundColor: '#ffffff',
          connectorWidth: 2,
          style: {
            textOutline: 0,
          },
        },
      };
    });
    return setTimelineChartOptions(chartOptions, heading);
  };

  setBulletChartOptions = (bulletChartData) => {
    this.setState({
      bulletChartData,
      isRightAxisAvailable: false,
      isPieChartsAvailable: false,
    });
  };
  setMilestoneChartOptions = (mileStones) => {
    const { event = [], heading = '' } = mileStones;
    let timelineChartData = {};
    // if (event && event.length > 0) {
    timelineChartData = this.generateTimelineChartData(event, heading);
    //}
    this.setState({
      timelineChartData,
      isRightAxisAvailable: false,
      isPieChartsAvailable: false,
    });
  };

  setGuageMeterChartOptions = (meter) => {
    const screenHeight = window.innerHeight;
    const newChartHeight = screenHeight * 0.5; // You can adjust the multiplier as needed
    const meterChartData = setMeterChartOptions(meter, newChartHeight);
    this.setState({
      meterChartData,
      isRightAxisAvailable: false,
      isPieChartsAvailable: false,
    });
  };

  setSpiderWebChartData = (spiderChartData) => {
    const spiderWebChartOptions = setSpiderWebChartOptions(spiderChartData);
    this.setState({
      spiderWebChartData: spiderWebChartOptions,
      isRightAxisAvailable: false,
      isPieChartsAvailable: false,
    });
  };

  setLollypopChartData = (lollypop) => {
    const lollypopChartOptions = setLollipopChartOptions(lollypop);
    this.setState({
      lollypopChartData: lollypopChartOptions,
      isRightAxisAvailable: false,
      isPieChartsAvailable: false,
    });
  };

  setBubbleChartData = (bubble) => {
    const bubbleChartData = setBubbleChartOptions(bubble);
    this.setState({
      bubbleChartData,
      isRightAxisAvailable: false,
      isPieChartsAvailable: false,
    });
  };
  generateChartData = () => {
    const { chartsData, isAux } = this.props;
    const { isShowRightAxis } = this.state;
    if (chartsData && Object.keys(chartsData).length > 0) {
      if (
        chartsData.subtabType === 'timeseries' &&
        chartsData.hasOwnProperty('timeSeries') &&
        chartsData.timeSeries
      ) {
        if (isAux) {
          this.setTimeseriesChartOptions(isShowRightAxis, 'aux');
        }
        this.setTimeseriesChartOptions(isShowRightAxis, 'main');
      } else if (
        chartsData.subtabType === 'milestones' &&
        chartsData.hasOwnProperty('milestones') &&
        chartsData.milestones
      ) {
        this.setMilestoneChartOptions(chartsData.milestones);
      } else if (
        chartsData.subtabType === 'bubble' &&
        chartsData.hasOwnProperty('bubble') &&
        chartsData.bubble &&
        chartsData.bubble.series 
        // &&
        // chartsData.bubble.series.length > 0
      ) {
        this.setBubbleChartData(chartsData.bubble);
      } else if (
        chartsData.subtabType === 'lollypop' &&
        chartsData.hasOwnProperty('lollypop') &&
        chartsData.lollypop &&
        chartsData.lollypop.series 
        // &&
        // chartsData.lollypop.series.length > 0
      ) {
        this.setLollypopChartData(chartsData.lollypop);
      } else if (
        chartsData.subtabType === 'bullet' &&
        chartsData.hasOwnProperty('bullet') &&
        chartsData.bullet &&
        chartsData.bullet.bulletSeries 
        // &&
        // chartsData.bullet.bulletSeries.length > 0
      ) {
        this.setBulletChartOptions(chartsData.bullet);
      } else if (
        chartsData.subtabType === 'meter' &&
        chartsData.hasOwnProperty('meter') &&
        chartsData.meter
      ) {
        this.setGuageMeterChartOptions(chartsData.meter);
      } else if (
        chartsData.subtabType === 'spiderweb' &&
        (chartsData.hasOwnProperty('spinderWeb') ||
          chartsData.hasOwnProperty('spiderWeb')) &&
        chartsData.spinderWeb &&
        chartsData.spinderWeb?.elements 
        // &&
        // chartsData.spinderWeb.elements?.length > 0
      ) {
        this.setSpiderWebChartData(chartsData.spinderWeb);
      } else {
        this.setState({ isRightAxisAvailable: false });
      }
      // else {
      //   this.isPieChartsAvailable();
      //   this.isRightAxisAvailable();
      // }
    }
  };

  componentDidMount() {
    const { chartsData } = this.props;
    if (chartsData && Object.keys(chartsData).length > 0) {
      this.generateChartData();
      this.generateSubChartsData();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedChart, chartsData } = this.props;
    const {
      isPieAtDrillDownLevel,
      isPieAtVariousAges,
      isDrillDown,
      canDataRender,
      isShowRightAxis,
    } = this.state;
    const { isShowRightAxis: prevIsShowRightAxis } = prevState;
    const { selectedChart: prevSelectedChart, chartsData: prevChartsData } =
      prevProps;
    if (
      (chartsData &&
        Object.keys(chartsData).length > 0 &&
        selectedChart !== '' &&
        (!isEqual(selectedChart, prevSelectedChart) ||
          !isEqual(chartsData, prevChartsData))) ||
      prevIsShowRightAxis !== isShowRightAxis
    ) {
      this.setState(
        {
          isDrillDown: false,
          isRightAxisAvailable: false,
          isPieAtDrillDownLevel: false,
          isPieAtVariousAges: false,
          mainChartData: {},
          auxChartData: {},
          timelineChartData: {},
          meterChartData: {},
          nowChartsData: {},
          nearTermData: {},
          bubbleChartData: {},
          lollypopChartData: {},
          mediumTermData: {},
          longTermData: {},
          bulletChartData: {},
          spiderWebChartData: {},
        },
        () => {
          this.generateChartData();
          this.generateSubChartsData();
        }
      );
    }
    if (
      isDrillDown &&
      isPieAtDrillDownLevel &&
      isPieAtVariousAges &&
      !canDataRender
    ) {
      this.setState({ canDataRender: true });
    }
  }

  switchToPieAtVariousAges = (event) => {
    const { isDrillDown } = this.state;
    const isPieAtVariousAges = event.target.checked ? true : false;
    if (isDrillDown && isPieAtVariousAges) {
      this.setState({ isPieAtVariousAges });
    } else {
      this.setState({ isPieAtVariousAges, isDrillDown: false });
    }
  };

  switchToPieAtDrillDownLevel = (event) => {
    const { isDrillDown } = this.state;
    const isPieAtDrillDownLevel = event.target.checked ? true : false;
    if (isDrillDown && isPieAtDrillDownLevel) {
      this.setState({ isPieAtDrillDownLevel, isPieAtVariousAges: true });
    } else {
      this.setState({
        isPieAtDrillDownLevel,
        isDrillDown: false,
        isPieAtVariousAges: false,
      });
    }
  };

  pushPinCharts = () => {
    const { triggerPushPinCharts, chronoTabItem, selectedChart } = this.props;
    const pushPinName = `${chronoTabItem} - ${selectedChart}`;
    const pushPinReq = {
      pushPinName: pushPinName,
    };
    triggerPushPinCharts({ pushPinReq, pushPinName });
  };

  showRightAxis = (event) => {
    const isShowRightAxis = event.target.checked ? true : false;
    this.setState({ isShowRightAxis });
  };

  render() {
    const {
      selectedChart,
      chartsData,
      isDashboard,
      isFaq,
      pushPinLabel,
      isSwitch = true,
      itemsLength,
      isAux,
    } = this.props;
    const {
      mainChartData,
      auxChartData,
      timelineChartData,
      meterChartData,
      nowChartsData,
      nearTermData,
      mediumTermData,
      bulletChartData,
      longTermData,
      isPieAtDrillDownLevel,
      isPieAtVariousAges,
      isPieChartsAvailable,
      isDrillDown,
      drillDownPieChartsData,
      isShowRightAxis,
      isRightAxisAvailable,
      bubbleChartData,
      lollypopChartData,
      spiderWebChartData,
    } = this.state;
    const { subtabType, subtabName } = chartsData;
    const chartInfo =
      (chartsData &&
        Object.keys(chartsData).length > 0 &&
        chartsData &&
        chartsData.timeSeries &&
        chartsData.timeSeries.main &&
        chartsData.timeSeries.main.yaxis &&
        chartsData.timeSeries.main.yaxis.length > 0 &&
        chartsData.timeSeries.main.yaxis.filter((f) => f.rightAxis)[0]) ||
      {};
    const size = itemsLength >= 3 ? 12 : 6;
    return (
      <div>
        <div
          className="ant-switch"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '5px',
          }}
        >
          {!isAux && isPieChartsAvailable && isDrillDown && isSwitch && (
            <FormControlLabel
              control={
                <AntSwitch
                  sx={{ m: 1 }}
                  checked={isPieAtDrillDownLevel}
                  onChange={this.switchToPieAtDrillDownLevel}
                />
              }
              label={'Pie at specific age'}
            />
          )}
          {!isAux && isPieChartsAvailable && !isDrillDown && isSwitch && (
            <FormControlLabel
              control={
                <AntSwitch
                  sx={{ m: 1 }}
                  checked={isPieAtVariousAges}
                  onChange={this.switchToPieAtVariousAges}
                />
              }
              label={'Pie (At Various Ages)'}
            />
          )}
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!isAux && isRightAxisAvailable && isSwitch && (
              <FormControlLabel
                control={
                  <AntSwitch
                    sx={{ m: 1 }}
                    checked={isShowRightAxis}
                    onChange={this.showRightAxis}
                  />
                }
                label={
                  (chartInfo &&
                    Object.keys(chartInfo).length > 0 &&
                    chartInfo.seriesName) ||
                  'Right axis'
                }
              />
            )}
            {!isAux && ( //temporary to hide pushpin for aux chart
              <PushPinIcon
                onClick={this.pushPinCharts}
                color={pushPinLabel === 'Pinned' ? 'success' : 'primary'}
              />
            )}
          </div> */}
        </div>
        <Grid container className="chrono-list-wrapper">
          {!isPieAtVariousAges ? (
            <Grid item xs={12} md={12} lg={12}>
              {selectedChart &&
              subtabType === 'timeseries' &&
              Object.keys(auxChartData).length > 0 &&
              isAux ? (
                <MainChartsContainer
                  options={auxChartData}
                  selectedChart={selectedChart}
                  chartType="aux"
                />
              ) : selectedChart &&
                subtabType === 'timeseries' &&
                Object.keys(mainChartData).length > 0 &&
                !isAux ? (
                <MainChartsContainer
                  options={mainChartData}
                  selectedChart={selectedChart}
                  chartType="main"
                />
              ) : !isAux &&
                selectedChart &&
                subtabType === 'milestones' &&
                Object.keys(timelineChartData).length > 0 ? (
                <TimelineChartContainer options={timelineChartData} />
              ) : !isAux &&
                selectedChart &&
                subtabType === 'bullet' &&
                bulletChartData &&
                bulletChartData?.bulletSeries?.length > 0 ? (
                bulletChartData?.bulletSeries.map((m, i) => {
                  const { chartTitle = '' } = bulletChartData || {};
                  return (
                    <ChartContainer
                      chartType="bullet"
                      chartTitle={i === 0 ? chartTitle : undefined}
                      selectedChart={'bullet'}
                      options={setBulletSeriesOptions(m, i)}
                    />
                  );
                })
              ) : !isAux &&
                selectedChart &&
                subtabType === 'bubble' &&
                Object.keys(bubbleChartData).length > 0 ? (
                <ChartContainer
                  chartType="bubble"
                  selectedChart={'bubble'}
                  options={bubbleChartData}
                />
              ) : !isAux &&
                selectedChart &&
                subtabType === 'lollypop' &&
                Object.keys(lollypopChartData).length > 0 ? (
                <ChartContainer
                  chartType="lollypop"
                  selectedChart={'lollypop'}
                  options={lollypopChartData}
                />
              ) : !isAux &&
                selectedChart &&
                subtabType === 'spiderweb' &&
                Object.keys(spiderWebChartData).length > 0 ? (
                <ChartContainer
                  chartType="spiderWeb"
                  selectedChart={'spiderWeb'}
                  options={spiderWebChartData}
                />
              ) : !isAux &&
                selectedChart &&
                subtabType === 'meter' &&
                Object.keys(meterChartData).length > 0 ? (
                <ChartContainer
                  chartType="meter"
                  selectedChart={selectedChart}
                  options={meterChartData}
                />
              ) : (
                <></>
              )}
            </Grid>
          ) : isPieAtDrillDownLevel && isDrillDown ? (
            <Grid item xs={12} md={12} lg={12}>
              {Object.keys(drillDownPieChartsData).length > 0 && (
                <ChartContainer
                  options={drillDownPieChartsData}
                  selectedChart={selectedChart}
                  chartType="main"
                />
              )}
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={size} lg={size}>
                {Object.keys(nowChartsData).length > 0 && (
                  <ChartContainer
                    chartType="now"
                    selectedChart={selectedChart}
                    options={nowChartsData}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={size} lg={size}>
                {Object.keys(nearTermData).length > 0 && (
                  <ChartContainer
                    chartType="near"
                    options={nearTermData}
                    selectedChart={selectedChart}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={size} lg={size}>
                {Object.keys(mediumTermData).length > 0 && (
                  <ChartContainer
                    chartType="medium"
                    options={mediumTermData}
                    selectedChart={selectedChart}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={size} lg={size}>
                {Object.keys(longTermData).length > 0 && (
                  <ChartContainer
                    chartType="long"
                    options={longTermData}
                    selectedChart={selectedChart}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
}

export default NewChartsContainer;
