// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  /* font-family: 'Noto Sans', Arial, sans-serif; */
  margin: 0;
  overflow: auto !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(180deg, #6ccea6 0%, #16899d 100%)
    rgba(255, 255, 255, 0.75);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: linear-gradient(180deg, #16899d 0%, #6ccea6 100%)
    rgba(255, 255, 255, 0.75);
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD;EACjD,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA,UAAU;AACV;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB;6BAC2B;AAC7B;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;EACnB;6BAC2B;AAC7B;;AAEA,0CAA0C;AAC1C;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,sBAAsB;AACtB;EACE,0BAA0B;AAC5B","sourcesContent":["body {\r\n  /* font-family: 'Noto Sans', Arial, sans-serif; */\r\n  margin: 0;\r\n  overflow: auto !important;\r\n}\r\n\r\n::-webkit-scrollbar {\r\n  width: 4px;\r\n  height: 4px;\r\n}\r\n\r\n/* Track */\r\n::-webkit-scrollbar-track {\r\n  background: #f1f1f1;\r\n  border-radius: 10px;\r\n}\r\n\r\n/* Handle */\r\n::-webkit-scrollbar-thumb {\r\n  border-radius: 10px;\r\n  background: linear-gradient(180deg, #6ccea6 0%, #16899d 100%)\r\n    rgba(255, 255, 255, 0.75);\r\n}\r\n\r\n/* Handle on hover */\r\n::-webkit-scrollbar-thumb:hover {\r\n  border-radius: 10px;\r\n  background: linear-gradient(180deg, #16899d 0%, #6ccea6 100%)\r\n    rgba(255, 255, 255, 0.75);\r\n}\r\n\r\n/* Works for Chrome, Safari, Edge, Opera */\r\ninput::-webkit-outer-spin-button,\r\ninput::-webkit-inner-spin-button {\r\n  -webkit-appearance: none;\r\n  margin: 0;\r\n}\r\n\r\n/* Works for Firefox */\r\ninput[type='number'] {\r\n  -moz-appearance: textfield;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
