import React from 'react';
import {
  setSubChartOptions,
  setBulletSeriesOptions,
  setBubbleChartOptions,
  setLollipopChartOptions,
  setTimelineChartOptions,
  setColumnChartOptions,
  setMainChartOptions,
  setMeterChartOptions,
  setSpiderWebChartOptions,
} from '../../components/shared/ChartData';
import ChronoChartsContainer from '../../components/ChronoChartsContainer';
import ChartContainer from '../../components/ChartContainer';
import TimelineChartContainer from '../../components/TimelineChartContainer';
import { orange, yellow, green, red, grey } from '@mui/material/colors';

export const CustomChart = (props) => {
  const { charts, renderWhatData, from } = props;

  const getColor = (color) => {
    switch (color && color.toLowerCase()) {
      case 'green':
        return '#00A36C';
      case 'lightgreen':
        return '#90EE90';
      case 'red':
        return '#C70039';
      case 'orange':
        return '#FFC300';
      case 'yellow':
        return yellow[600];
      default:
        return grey[600];
    }
  };

  const generateChartData = (milestones, heading = 'Key Events') => {
    let chartOptions = milestones.filter(
      (m) => m.year !== 0 && m.year !== null
    );
    chartOptions = chartOptions.sort((a, b) => a.year - b.year);
    chartOptions = chartOptions.map((m, index) => {
      return {
        name: m.name + '<br/>' + m.year + `${m.desc}`,
        color: getColor(m.healthFlag ? m.healthFlag : 'grey'),
        dataLabels: {
          color: '#000000',
          backgroundColor: '#ffffff',
          connectorWidth: 2,
          style: {
            textOutline: 0,
          },
        },
      };
    });
    return setTimelineChartOptions(chartOptions, heading);
  };
  const getCategories = (columnChartData, notfullOffset) => {
    const series =
      (columnChartData.drilldown &&
        columnChartData.drilldown.series &&
        columnChartData.drilldown.series.length > 0 &&
        columnChartData.drilldown.series[notfullOffset]) ||
      {};
    let categories = [];
    series.data && series.data.forEach((m) => categories.push(m[0]));
    return categories;
  };

  const getIndividualPieSeriesData = (data) => {
    return data.map((x) => {
      return {
        name: x[0],
        y: x[1] > 0 ? x[1] : null,
      };
    });
  };

  const getPieSeries = (columnChartData, notfullOffset) => {
    const series =
      (columnChartData.drilldown &&
        columnChartData.drilldown.series &&
        columnChartData.drilldown.series.length > 0 &&
        columnChartData.drilldown.series[notfullOffset]) ||
      {};
    return [
      {
        name: series.name,
        colorByPoint: true,
        data: getIndividualPieSeriesData(series.data),
      },
    ];
  };

  const getSeries = (columnChartData, notfullOffset) => {
    const series =
      (columnChartData.drilldown &&
        columnChartData.drilldown.series &&
        columnChartData.drilldown.series.length > 0 &&
        columnChartData.drilldown.series[notfullOffset]) ||
      {};
    const seriesData = [];
    let seriesOptions = [];
    series.data && series.data.forEach((m) => seriesData.push(m[1]));
    if (seriesData.length > 0) {
      seriesOptions = [{ name: series.name || '', data: seriesData }];
    }
    return seriesOptions;
  };

  const getPieChartOptions = (columnChartData, notfullOffset) => {
    const series =
      (columnChartData.drilldown &&
        columnChartData.drilldown.series &&
        columnChartData.drilldown.series.length > 0 &&
        columnChartData.drilldown.series[notfullOffset]) ||
      {};
    const { title = {} } = columnChartData || {};
    const options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: title,
      colors: [
        '#459270',
        '#52BE81',
        '#5AAE60',
        '#8AD184',
        '#DEE07F',
        '#7FCDBB',
        '#C7E9B4',
        '#EDF8B1',
        '#DFDF8B',
        '#41B6C4',
        '#465582',
      ],
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name} ({point.percentage:.0f}%)',
          },
        },
      },
      series: getPieSeries(columnChartData, notfullOffset),
    };
    return options;
  };

  const getChartOptions = (columnChartData, notfullOffset) => {
    const options = {
      chart: { type: 'column' },
      title: {
        text: columnChartData?.title?.text || '',
      },
      colors: [
        '#459270',
        '#52BE81',
        '#5AAE60',
        '#8AD184',
        '#DEE07F',
        '#7FCDBB',
        '#C7E9B4',
        '#EDF8B1',
        '#DFDF8B',
        '#41B6C4',
        '#465582',
      ],
      xAxis: {
        categories: getCategories(columnChartData, notfullOffset),
        crosshair: true,
        title: { text: columnChartData?.xAxis?.title?.text || '' },
      },
      yAxis: {
        // min: 0,
        title: {
          text: columnChartData?.yAxis[0]?.title?.text || '',
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: getSeries(columnChartData, notfullOffset),
    };
    return options;
  };

  const renderTimeseriesSubChart = (selectedSubChartData, renderData) => {
    const { subtabName, chart } = renderData;
    const { notfullIsItPie, notfullOffset } = chart;
    if (selectedSubChartData.timeSeries.sub.length > 0) {
      let pieChartsData = {};
      let columnChartData = {};
      const yAxisLeftLabel =
        selectedSubChartData.timeSeries.main.yaxisLeftLabel &&
          selectedSubChartData.timeSeries.main.yaxisLeftLabel !== ''
          ? selectedSubChartData.timeSeries.main.yaxisLeftLabel
          : '';
      if (notfullIsItPie) {
        pieChartsData = setSubChartOptions(
          selectedSubChartData.timeSeries.sub[notfullOffset],
          'cta',
          yAxisLeftLabel
        );
        return (
          <ChartContainer
            chartType={'pie'}
            selectedChart={subtabName}
            options={pieChartsData}
          />
        );
      } else {
        columnChartData = setColumnChartOptions(
          selectedSubChartData.timeSeries.sub[notfullOffset],
          undefined,
          yAxisLeftLabel
        );
        return (
          <ChartContainer
            chartType={'column'}
            selectedChart={subtabName}
            options={columnChartData}
          />
        );
      }
    }
  };

  const renderTimeSeriesChart = (type, renderData, selectedSubChartData) => {
    const { subtabName, chart } = renderData;
    const {
      isItFullIfTimeSeriesMain,
      notfullIsItPie,
      notfullOffset,
      typeIfTimeSeries,
    } = chart;
    let columnChartData = {};
    if (isItFullIfTimeSeriesMain) {
      return (
        <ChronoChartsContainer
          selectedChart={subtabName}
          chartsData={selectedSubChartData.timeSeries}
          isDashboard={false}
          isHome={false}
          series={typeIfTimeSeries}
          from={from}
        />
      );
    } else {
      columnChartData = setMainChartOptions(
        selectedSubChartData.timeSeries[type]
      );
      if (notfullIsItPie) {
        const options = getPieChartOptions(columnChartData, notfullOffset);
        return (
          <ChartContainer
            chartType={'pie'}
            selectedChart={subtabName}
            options={options}
          />
        );
      } else {
        const options = getChartOptions(columnChartData, notfullOffset);
        return (
          <ChartContainer
            chartType={'column'}
            selectedChart={subtabName}
            options={options}
          />
        );
      }
    }
  };
  const renderCustomChart = () => {
    if (charts && charts.length > 0) {
      const { tabName, subtabName, chart } = renderWhatData;
      const { seriesType, typeIfTimeSeries } = chart || {};
      const selectedChartsData = charts.filter(
        (c) =>
          c.tabName &&
          tabName &&
          c.tabName.toLowerCase() === tabName.toLowerCase()
      )[0];
      const selectedSubChartData =
        (selectedChartsData &&
          selectedChartsData.subtabs &&
          selectedChartsData.subtabs.length > 0 &&
          selectedChartsData.subtabs
            .filter((s) => s.subtabName) //filter out nulls
            .filter(
              (f) =>
                subtabName &&
                f.subtabName.toLowerCase() === subtabName.toLowerCase()
            )[0]) ||
        {};
      if (
        selectedSubChartData &&
        selectedSubChartData.timeSeries &&
        Object.keys(selectedSubChartData.timeSeries).length > 0
      ) {
        if (
          seriesType === 'timeseries' &&
          (selectedSubChartData.timeSeries.main ||
            selectedSubChartData.timeSeries.aux) &&
          (Object.keys(selectedSubChartData.timeSeries.main).length > 0 ||
            Object.keys(selectedSubChartData.timeSeries.aux).length > 0) &&
          selectedSubChartData.timeSeries.sub &&
          selectedSubChartData.timeSeries.sub.length >= 0
        ) {
          switch (typeIfTimeSeries) {
            case 'main':
              return renderTimeSeriesChart(
                typeIfTimeSeries,
                renderWhatData,
                selectedSubChartData
              );
            case 'aux':
              return renderTimeSeriesChart(
                typeIfTimeSeries,
                renderWhatData,
                selectedSubChartData
              );
            case 'sub':
              return renderTimeseriesSubChart(
                selectedSubChartData,
                renderWhatData
              );
            default:
              break;
          }
        }
      } else if (
        seriesType === 'bullet' &&
        selectedSubChartData &&
        selectedSubChartData.bullet &&
        selectedSubChartData.bullet.bulletSeries &&
        selectedSubChartData.bullet.bulletSeries.length > 0
      ) {
        return (
          <>
            {selectedSubChartData.bullet.bulletSeries.map((m, i) => {
              const { chartTitle = '' } = selectedSubChartData.bullet || {};
              return (
                <ChartContainer
                  chartType="bullet"
                  chartTitle={i === 0 ? chartTitle : undefined}
                  selectedChart={'bullet'}
                  options={setBulletSeriesOptions(m, i)}
                />
              );
            })}
          </>
        );
      } else if (
        seriesType === 'milestones' &&
        selectedSubChartData &&
        selectedSubChartData.milestones
        // &&
        // selectedSubChartData.milestones.event &&
        // selectedSubChartData.milestones.event.length > 0
      ) {
        const timelineChartData = generateChartData(
          selectedSubChartData.milestones.event || [],
          selectedSubChartData.milestones.heading || 'Key Events'
        );
        return <TimelineChartContainer options={timelineChartData} />;
      } else if (
        seriesType === 'bubble' &&
        selectedSubChartData &&
        selectedSubChartData.bubble &&
        selectedSubChartData.bubble.series &&
        selectedSubChartData.bubble.series.length > 0
      ) {
        const bubbleChartOptions = setBubbleChartOptions(
          selectedSubChartData.bubble
        );
        return (
          <ChartContainer
            chartType="bubble"
            selectedChart={'bubble'}
            options={bubbleChartOptions}
          />
        );
      } else if (
        seriesType === 'lollypop' &&
        selectedSubChartData &&
        selectedSubChartData.lollypop &&
        selectedSubChartData.lollypop.series &&
        selectedSubChartData.lollypop.series.length > 0
      ) {
        const lollipopChartOptions = setLollipopChartOptions(
          selectedSubChartData.lollypop
        );
        return (
          <ChartContainer
            chartType="lollipop"
            selectedChart={'lollipop'}
            options={lollipopChartOptions}
          />
        );
      } else if (
        seriesType === 'meter' &&
        selectedSubChartData &&
        selectedSubChartData.hasOwnProperty('meter') &&
        selectedSubChartData.meter
      ) {
        const screenHeight = window.innerHeight;
        const newChartHeight = screenHeight * 0.5; // You can adjust the multiplier as needed
        const meterChartData = setMeterChartOptions(
          selectedSubChartData.meter,
          newChartHeight
        );
        return (
          <ChartContainer
            chartType="meter"
            selectedChart={'meter'}
            options={meterChartData}
          />
        );
      } else if (
        seriesType === 'spiderWeb' &&
        selectedSubChartData &&
        selectedSubChartData.hasOwnProperty('spinderWeb') &&
        selectedSubChartData.spinderWeb &&
        selectedSubChartData.spinderWeb?.elements &&
        selectedSubChartData.spinderWeb.elements?.length > 0
      ) {
        const spiderChartData = setSpiderWebChartOptions(
          selectedSubChartData.spinderWeb
        );
        return (
          <ChartContainer
            chartType="spider"
            selectedChart={'spider'}
            options={spiderChartData}
          />
        );
      }
    }
  };
  return <>{renderCustomChart()}</>;
};
export default CustomChart;
