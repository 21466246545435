export const validatePhoneNumber = (phoneNumber) => {
  const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  if (phoneNumber) {
    // Test if the phone number matches the regex pattern
    return phoneRegex.test(phoneNumber);
  }
  return false;
};

export const validateEmailId = (email) => {
  const emailRegex =
    /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/;
  if (email) {
    return emailRegex.test(email);
  }
  return false;
};

export const formatPhoneNumber = (phoneNumber) => {
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  let formattedNumber = '';
  if (digitsOnly) {
    if (digitsOnly.length <= 3) {
      formattedNumber = `(${digitsOnly}`;
    } else if (digitsOnly.length <= 6) {
      formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3)}`;
    } else {
      formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(
        3,
        6
      )}-${digitsOnly.slice(6, 10)}`;
    }
  }
  return formattedNumber;
};

export const validateInputFieId = (value) => {
  if (value.trim() === '') {
    return false;
  }
  return true;
};

export const validateNameField = (name) => {
  const nameRegex = /^[A-Za-z0-9](?:[A-Za-z0-9 ]*[A-Za-z0-9])?$/; ///^[A-Za-z][A-Za-z ]*[A-Za-z]$/; ///^[a-zA-Z\s'-]+$/;
  if (nameRegex.test(name)) {
    if (name.indexOf('  ') === -1) {
      return true;
    }
  }
  return false;
};

export const validateNamesWithoutSpaces = (name) => {
  // const nameRegex = /^([A-Za-z0-9_-\s]+)$/;
  const nameRegex = /^[a-zA-Z0-9][a-zA-Z0-9 _-]*$/;
  // const nameRegex = /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)?$/;
  // if (nameRegex.test(name)) {
  //   return true;
  // }

  // return false;
  return nameRegex.test(name);
};

export const validateAgeFieId = (fieldValue, memberModalFormDetails, from) => {
  const { age = 0, retirementAge = 0 } = memberModalFormDetails;
  const ageRegex = /^(?:1[0-1]\d|120|[1-9]\d?)$/;
  let isAgeRegexValid = false;
  if (fieldValue && !isNaN(fieldValue)) {
    isAgeRegexValid = ageRegex.test(fieldValue);
    if (from && isAgeRegexValid) {
      switch (from.toLowerCase()) {
        case 'age':
        case 'retirementage':
          return fieldValue >= 18;
        // case 'retirementage':
        //   return fieldValue >= 18;
        default:
          return true;
      }
    }
  }
  return false;
};

export const validateCurrencyFields = (currency) => {
  let currencyFieldValue = '';
  if (currency && currency.toString().includes('$')) {
    currencyFieldValue = currency.split('$')[1];
    currencyFieldValue = currencyFieldValue.replace(/,/g, '');
    if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
      currencyFieldValue = parseFloat(currencyFieldValue);
    } else {
      currencyFieldValue = 0;
    }
  } else if (currency && !isNaN(currency)) {
    currencyFieldValue = parseFloat(currency);
  } else {
    currencyFieldValue = 0;
  }
  return currencyFieldValue >= 0 ? true : false; // revisit for few scenarios where currency shd be greater than 0
};

export const validatePercentageFields = (value) => {
  let percentFieldValue = '';
  if (value !== null && value !== undefined && value !== '') {
    if (value.toString().includes('%')) {
      percentFieldValue = value.split('%')[0];
      if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
        percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
      } else {
        percentFieldValue = 0.0;
      }
    } else if (value && !isNaN(value)) {
      percentFieldValue = parseFloat(value).toFixed(2);
    } else {
      percentFieldValue = 0.0;
    }
  } else {
    percentFieldValue = 0.0;
  }
  return percentFieldValue ? true : false;
};

export const validateDateFields = (val, view) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const pastDate = yesterday.setUTCHours(0);
  let selectedYear = val;
  let selectedDate = new Date(val.toString()); // jan 01 2024
  selectedDate = selectedDate.setUTCHours(0);
  if (view === 'year' && selectedDate < pastDate) {
    return false;
  }
  return true;
};

export const checkIfYearIsValid = (selectedYear, view) => {
  const inputDt = new Date(new Date().setFullYear(selectedYear));
  return inputDt.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
};

export const isDate1AfterDate2 = (date1, date2) => {
  const date1obj = Date.parse(date1);
  const date2obj = Date.parse(date2);
  return date1obj >= date2obj;
};
