import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';

export default function RadioGroup(props) {
  const { name, label, value, onChange, onClick, items, rowType = true, disabled = false } = props;

  return (
    <FormControl className="controls-radiogroup">
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup
        row={rowType}
        name={name}
        value={value}
        onChange={onChange}
        onClick={onClick}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            disabled={item.disabled || disabled}
            control={<Radio />}
            label={
              <>
                {item.showProcessed ? (
                  <>
                    <label>{item.title}</label>
                    <CheckCircleIcon
                      sx={{
                        color: green[800],
                        fontSize: '25px',
                        marginLeft: '10px',
                      }}
                    />
                  </>
                ) : (
                  <label>{item.title}</label>
                )}
              </>
            }
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
