import React, { useEffect, useState } from 'react';
import {
  renderMembersInputRow,
  renderMemberLabel,
  transformRothChoicesBackToUIType,
} from '../helpers/BuildHelperComponentV3.js';
import { isEqual } from 'lodash';

export const BuildEstatePlanningRothComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    isFirstPersonRetired,
    isSecondPersonRetired,
    membersCount,
  } = props;

  const state = {
    isFirstPersonRetired,
    isSecondPersonRetired,
    membersCount,
    memberOne: false,
    memberTwo: false,
  };
  const {
    moduleEstate: { rothChoices = {} } = {},
    moduleFamily: { infoCore = {} } = {},
  } = localPlanDetailsByPlanId;

  const [rothChoicesDetails, setRothChoicesDetails] = useState(rothChoices);

  const withDrawRothFirst = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  useEffect(() => {
    if (rothChoices && Object.keys(rothChoices).length > 0) {
      let tempRothChoices = transformRothChoicesBackToUIType(rothChoices);
      if (!isEqual(tempRothChoices, rothChoices)) {
        setRothChoicesDetails(tempRothChoices);
      }
    }
  }, [localPlanDetailsByPlanId, rothChoices]);

  return (
    <>
      <div className="insured_main_outer">
        {renderMemberLabel(
          infoCore,
          'name',
          'basicsFirst',
          'basicsSecond',
          state
        )}
      </div>
      <hr style={{ marginTop: '0px' }} />
      <div className="insured_main_outer">
        {renderMembersInputRow(
          'text',
          'tlOneTradIraToRothPercent',
          'tradIraToRothPercent',
          'tlTwoTradIraToRothPercent',
          'What % of Traditional IRA would you like to consider converting to ROTH now',
          'percentage',
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          'first',
          'second',
          state,
          setRothChoicesDetails
        )}
        {renderMembersInputRow(
          'text',
          'tlOneTrad401kToRothPercentNow',
          'trad401kToRothPercentNow',
          'tlTwoTrad401kToRothPercentNow',
          'What % of Traditional 401k would you like to consider converting to ROTH now',
          'percentage',
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          'first',
          'second',
          state,
          setRothChoicesDetails
        )}
        {renderMembersInputRow(
          'text',
          'tlOneTrad401kToRothPercentAtRet',
          'trad401kToRothPercentAtRet',
          'tlTwoTrad401kToRothPercentAtRet',
          'What % of Traditional 401k would you like to consider converting to ROTH at retirement',
          'percentage',
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          'first',
          'second',
          state,
          setRothChoicesDetails
        )}
      </div>
    </>
  );
};

export default BuildEstatePlanningRothComponent;
