import React, { useEffect } from 'react';
import {
  renderFormInputRow,
  renderFormInputRowName,
  renderFooterSubmitCancelButtons,
  renderSelectAccountDropdown,
  renderFormDateField,
  DeleteConfirmation,
  renderFormSelectOption,
  validateFormDetails,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const BuildExpensesKidsComponent = (props) => {
  const {
    state,
    concatContent,
    handleDelete,
    setLastStepInParent,
    setValidationError,
    isFormValid,
    formErrors,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleFamily',
    mainTab: 'infoKids',
    subTab: 'kids',
    countVal: 'numKids',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  const userSavingOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  // const validateFields = [
  //   {
  //     apiFieldName: 'name',
  //     errorMessage: 'Invalid Name',
  //   },
  // ];

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName, 
          'Select Kid', 
          openDeleteConfirmation,
          props,
          state,
          moduleObject,
          false,
          false
        )}
      </div>

      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          {renderFormInputRow(
            'text',
            'tlAnnualExpense',
            'annualExpense',
            'Additional annual expenses due to kid including child care etc.',
            'currency',
            moduleObject,
            state,
            props
          )}
          {isAddNew || isEditable ? (
            <div className="insured_main_outer">
              {renderFormSelectOption(
                'text',
                'tluseSavingsFor529Deficit',
                'useSavingsFor529Deficit',
                'Do you want to use non-retirment funds at the time of attending college if 529 plan savings are not sufficient ?',
                userSavingOptions,
                moduleObject,
                state,
                props
              )}
            </div>
          ) : (
            <div className="insured_main_outer">
              {renderFormInputRow(
                'text',
                'tluseSavingsFor529Deficit',
                'useSavingsFor529Deficit',
                'Do you want to use non-retirment funds at the time of attending college if 529 plan savings are not sufficient ?',
                'dropdown',
                moduleObject,
                state,
                props
              )}
            </div>
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}

      <div className="tab_main_nav">
        <div className="tab_left_nav">
          <button>
            <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className="tab_right_nav">
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
};

export default BuildExpensesKidsComponent;
