import omit from 'lodash/omit';
import { dependentFields as wagesAndExpenseProfileDependentFields } from '../../constants/LifeStyle/wagesAndExpenseProfileConstants';
import { dependentFields as savingsDetailsDependentFields } from '../../constants/LifeStyle/savingsDetailConstants';
import { dependentFields as investmentBalancesFields } from '../../constants/Investments/investmentBalancesConstants';
import { dependentFields as retirementChoicesDependentFields } from '../../constants/Retirement/retirementChoicesConstants';
import {
  defaultReviewWagesAndExpenseProfileInitialState,
  defaultSavingsDetailsInitialState,
  defaultExpenseDetailsInitialState,
  defaultExpenseDetailsInitialStateV2,
} from '../../constants/LifeStyle/lifeStyleDefaultState';
import { updateDateTimeStampsToUserTimezone } from '../../utils/dateTimestampTransformer';
import { planForVehicleConstants } from '../../constants/LifeEvents/planForVehicleConstants';
import { studentLoanConstants } from '../../constants/Debt/studentLoanConstants';
import { otherLoansConstants } from '../../constants/Debt/otherLoansConstants';
import { primaryHomeConstants } from '../../constants/RealEstate/primaryHomeConstants';
import { secondHomesConstants } from '../../constants/RealEstate/secondHomesConstants';
import { investmentPropertiesConstants } from '../../constants/RealEstate/investmentPropertiesConstants';
import { commonFields } from '../../constants/DashboardPlannerFields';

let memberName = {
  elder: '',
  younger: '',
};

const convertToLocalTimezone = (data, dateFields) => {
  if (data) {
    dateFields.forEach((field) => {
      updateDateTimeStampsToUserTimezone(field, data);
    });
    return data;
  }
};

export const getWagesAndExpenseProfileStoreValues = (wageAndExpenseData) => {
  const wagesAndExpenseProfile = Object.keys(wageAndExpenseData)
    .sort()
    .reduce((obj, key) => {
      obj[key] = wageAndExpenseData[key];
      return obj;
    }, {});
  if (Object.keys(wagesAndExpenseProfile).length > 0) {
    const commonAttr = wagesAndExpenseProfileDependentFields.reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr]: wagesAndExpenseProfile[curr],
        };
      },
      {}
    );

    const otherValues = Object.keys(wagesAndExpenseProfile).reduce(
      (acc, curr) => {
        if (
          wagesAndExpenseProfile[curr] &&
          typeof wagesAndExpenseProfile[curr] === 'object' &&
          Object.keys(wagesAndExpenseProfile[curr]).length > 0
        ) {
          memberName = {
            ...memberName,
            [curr]: wagesAndExpenseProfile[curr].name,
          };
          acc.push({
            [memberName[curr]]: {
              ...wagesAndExpenseProfile[curr],
              name: curr,
              label: memberName[curr],
              // ...commonAttr,
            },
          });
        }
        return acc;
      },
      []
    );
    const newOtherValues = {
      ...commonAttr,
      memberValues: otherValues,
    };
    return newOtherValues;
  }
  return defaultReviewWagesAndExpenseProfileInitialState;
};

export const getSavingsDetailsStoreValues = (savingsDetailsInfo) => {
  if (Object.keys(savingsDetailsInfo).length > 0) {
    const commonAttr = savingsDetailsDependentFields.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: savingsDetailsInfo[curr],
      };
    }, {});

    const otherValues = Object.keys(savingsDetailsInfo).reduce((acc, curr) => {
      if (
        savingsDetailsInfo[curr] &&
        typeof savingsDetailsInfo[curr] === 'object' &&
        Object.keys(savingsDetailsInfo[curr]).length > 0
      ) {
        acc.push({
          [memberName[curr]]: {
            ...savingsDetailsInfo[curr],
            name: curr,
            label: memberName[curr],
          },
        });
      }
      return acc;
    }, []);
    const newOtherValues = {
      ...commonAttr,
      memberValues: otherValues,
    };
    return newOtherValues;
  }
  return defaultSavingsDetailsInitialState;
};

export const getInfoExpenseStoreValuesV2 = (
  infoExpenseDetails,
  expenseFactorDetails
) => {
  if (Object.keys(infoExpenseDetails).length > 0) {
    const { disc, amounts, horFactor, retFactor, ...rest } = infoExpenseDetails;
    const { aggNonHousing, ...discRest } = disc;
    const { aggNonHousing: agg, ...horizonRest } = horFactor;
    const { aggNonHousing: retagg, ...retRest } = retFactor;
    const discExpenseDetails = Object.keys(discRest).reduce((acc, curr) => {
      return { ...acc, [`${curr}`]: discRest[curr] };
    }, {});
    // const horizonDetails = Object.keys(horizonRest).reduce((acc, curr) => {
    //   return { ...acc, [`horizon${curr}`]: horizonRest[curr] };
    // }, {});
    // const retirementExpenseDetails = Object.keys(retRest).reduce(
    //   (acc, curr) => {
    //     return { ...acc, [`retirement${curr}`]: retRest[curr] };
    //   },
    //   {}
    // );
    return {
      amounts: {
        ...amounts,
      },
      disc: {
        ...discExpenseDetails,
      },
      horFactor,
      retFactor,
      ...rest,
    };
  }
  if (expenseFactorDetails && expenseFactorDetails.length > 0) {
    const {
      discreationaryflag = false,
      horizonfactor = '',
      retfactor = '',
    } = expenseFactorDetails[0];
    const { amounts, disc, horFactor, retFactor, ...rest } =
      defaultExpenseDetailsInitialStateV2;
    return {
      amounts: {
        ...amounts,
      },
      disc: {
        ...disc,
        useDiscretionaryFlag: discreationaryflag,
      },
      horFactor: {
        ...horFactor,
        discHorFactor: horizonfactor,
      },
      retFactor: {
        ...retFactor,
        // discRetFactor: retfactor,
      },
      ...rest,
    };
  }
  return defaultExpenseDetailsInitialStateV2;
};

export const getSolutionsExpenseStoreValuesV2 = (
  infoExpenseDetails,
  expenseFactorDetails
) => {
  if (Object.keys(infoExpenseDetails).length > 0) {
    const { disc, amounts, horFactor, retFactor, ...rest } = infoExpenseDetails;
    const { aggNonHousing, ...discRest } = disc;
    const { aggNonHousing: agg, ...horizonRest } = horFactor;
    const { aggNonHousing: retagg, ...retRest } = retFactor;
    const discExpenseDetails = Object.keys(discRest).reduce((acc, curr) => {
      return { ...acc, [`${curr}`]: discRest[curr] };
    }, {});
    // const horizonDetails = Object.keys(horizonRest).reduce((acc, curr) => {
    //   return { ...acc, [`horizon${curr}`]: horizonRest[curr] };
    // }, {});
    // const retirementExpenseDetails = Object.keys(retRest).reduce(
    //   (acc, curr) => {
    //     return { ...acc, [`retirement${curr}`]: retRest[curr] };
    //   },
    //   {}
    // );
    return {
      amounts: {
        ...amounts,
      },
      disc: {
        ...discExpenseDetails,
      },
      horFactor,
      retFactor,
      ...rest,
    };
  }
  if (expenseFactorDetails && expenseFactorDetails.length > 0) {
    const {
      discreationaryflag = false,
      horizonfactor = '',
      retfactor = '',
    } = expenseFactorDetails[0];
    const { amounts, disc, horFactor, retFactor, ...rest } =
      defaultExpenseDetailsInitialStateV2;
    return {
      amounts: {
        ...amounts,
      },
      disc: {
        ...disc,
        useDiscretionaryFlag: discreationaryflag,
      },
      horFactor: {
        ...horFactor,
        discHorFactor: horizonfactor,
      },
      retFactor: {
        ...retFactor,
        // discRetFactor: retfactor,
      },
      ...rest,
    };
  }
  return defaultExpenseDetailsInitialStateV2;
};

export const getInfoExpenseStoreValues = (
  infoExpenseDetails,
  expenseFactorDetails
) => {
  if (Object.keys(infoExpenseDetails).length > 0) {
    const { disc, expenses, horFactor, retFactor, ...rest } =
      infoExpenseDetails;
    const { aggNonHousing, ...discRest } = disc;
    const { aggNonHousing: agg, ...horizonRest } = horFactor;
    const { aggNonHousing: retagg, ...retRest } = retFactor;
    const discExpenseDetails = Object.keys(discRest).reduce((acc, curr) => {
      return { ...acc, [`${curr}`]: discRest[curr] };
    }, {});
    const horizonDetails = Object.keys(horizonRest).reduce((acc, curr) => {
      return { ...acc, [`horizon${curr}`]: horizonRest[curr] };
    }, {});
    const retirementExpenseDetails = Object.keys(retRest).reduce(
      (acc, curr) => {
        return { ...acc, [`retirement${curr}`]: retRest[curr] };
      },
      {}
    );
    return {
      expenses: {
        ...expenses,
      },
      disc: {
        ...discExpenseDetails,
      },
      horFactor: {
        ...horizonDetails,
      },
      retFactor: {
        ...retirementExpenseDetails,
      },
      ...rest,
    };
  }
  if (expenseFactorDetails && expenseFactorDetails.length > 0) {
    const {
      discreationaryflag = false,
      horizonfactor = '',
      retfactor = '',
    } = expenseFactorDetails[0];
    const { expenses, disc, horFactor, retFactor, ...rest } =
      defaultExpenseDetailsInitialState;
    return {
      expenses: {
        ...expenses,
      },
      disc: {
        ...disc,
        useDiscretionaryFlag: discreationaryflag,
      },
      horFactor: {
        ...horFactor,
        discHorFactor: horizonfactor,
      },
      retFactor: {
        ...retFactor,
        // discRetFactor: retfactor,
      },
      ...rest,
    };
  }
  return defaultExpenseDetailsInitialState;
};

export const getKidsDetailsStoreValues = (kidsDetailsInfo, constants) => {
  const dateFields =
    constants &&
    constants
      .filter((f) => f.type === 'date')
      .map((m) => {
        return {
          key: m.name,
          dateFormat: 'yyyy-MM-dd',
        };
      });
  return kidsDetailsInfo.map((kid) => ({
    ...kid,
    label: kid.name || memberName[kid.name],
    name: kid.name,
  }));
};

export const getInfoPreferenceStoreValues = (infoPreferences) => {
  return { ...infoPreferences };
};

export const getInsuranceLiabilityStoreValues = (liabilityIns) => {
  return (
    (liabilityIns &&
      liabilityIns.map((ins) => ({
        ...ins,
        label: ins.name,
      }))) ||
    []
  );
};

export const getInvestmentBalanceStoreValues = (investmentBalance) => {
  const commonAttr = investmentBalancesFields.reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: investmentBalance[curr],
    };
  }, {});
  const investmentBalanceStoreValue = Object.keys(investmentBalance)
    .sort()
    .reduce((obj, key) => {
      obj[key] = investmentBalance[key];
      return obj;
    }, {});
  const otherValues = Object.keys(investmentBalanceStoreValue).reduce(
    (acc, curr) => {
      if (
        investmentBalanceStoreValue[curr] &&
        typeof investmentBalanceStoreValue[curr] === 'object' &&
        Object.keys(investmentBalanceStoreValue[curr]).length > 0
      ) {
        acc.push({
          [memberName[curr]]: {
            ...investmentBalanceStoreValue[curr],
            name: curr,
            label: memberName[curr],
          },
        });
      }
      return acc;
    },
    []
  );
  const newOtherValues = {
    ...commonAttr,
    memberValues: otherValues,
  };
  return newOtherValues;
};

export const getInvestmentVolatilityStoreValues = (investmentVolatility) => {
  const investmentVolatilityStoreValue = Object.keys(investmentVolatility)
    .sort()
    .reduce((obj, key) => {
      obj[key] = investmentVolatility[key];
      return obj;
    }, {});
  const otherValues = Object.keys(investmentVolatilityStoreValue).reduce(
    (acc, curr) => {
      if (
        investmentVolatilityStoreValue[curr] &&
        typeof investmentVolatilityStoreValue[curr] === 'object' &&
        Object.keys(investmentVolatilityStoreValue[curr]).length > 0
      ) {
        acc.push({
          [memberName[curr]]: {
            ...investmentVolatilityStoreValue[curr],
            name: curr,
            label: memberName[curr],
          },
        });
      }
      return acc;
    },
    []
  );
  const newOtherValues = {
    memberValues: otherValues,
  };
  return newOtherValues;
};

export const getInvestmentReturnsStoreValues = (investmentReturns) => {
  const investmentReturnsStoreValue = Object.keys(investmentReturns)
    .sort()
    .reduce((obj, key) => {
      obj[key] = investmentReturns[key];
      return obj;
    }, {});
  const otherValues = Object.keys(investmentReturnsStoreValue).reduce(
    (acc, curr) => {
      if (
        investmentReturnsStoreValue[curr] &&
        typeof investmentReturnsStoreValue[curr] === 'object' &&
        Object.keys(investmentReturnsStoreValue[curr]).length > 0
      ) {
        acc.push({
          [memberName[curr]]: {
            ...investmentReturnsStoreValue[curr],
            name: curr,
            label: memberName[curr],
          },
        });
      }
      return acc;
    },
    []
  );
  const newOtherValues = {
    memberValues: otherValues,
  };
  return newOtherValues;
};

export const getPrimaryHomeStoreValues = (primaryHomeDetails, constants) => {
  const dateFields = constants
    .filter((f) => f.type === 'date')
    .map((m) => {
      return {
        key: m.name,
        dateFormat: 'yyyy-MM-dd',
      };
    });
  let primaryHomeInfo = [];
  if (Object.keys(primaryHomeDetails).length > 0) {
    primaryHomeInfo = getInsuranceLiabilityStoreValues([
      {
        ...primaryHomeDetails,
      },
    ]);
  }
  return primaryHomeInfo;
};

export const getSecondHomesStoreValues = (secondHomesDetails, constants) => {
  const dateFields = constants
    .filter((f) => f.type === 'date')
    .map((m) => {
      return {
        key: m.name,
        dateFormat: 'yyyy-MM-dd',
      };
    });
  return getInsuranceLiabilityStoreValues(secondHomesDetails);
};

export const getInvestmentPropertiesStoreValues = (
  investmentProperties,
  constants
) => {
  const dateFields = constants
    .filter((f) => f.type === 'date')
    .map((m) => {
      return {
        key: m.name,
        dateFormat: 'yyyy-MM-dd',
      };
    });
  return investmentProperties.map((invProp) => {
    const { prop, ...rest } = invProp;
    return { ...prop, ...rest, label: prop.name, name: prop.name };
  });
};

export const getRothVsTraditionalStoreValues = (rothVsTraditional) => {
  const otherValues = Object.keys(rothVsTraditional).reduce((acc, curr) => {
    if (
      rothVsTraditional[curr] &&
      typeof rothVsTraditional[curr] === 'object' &&
      Object.keys(rothVsTraditional[curr]).length > 0
    ) {
      acc.push({
        [memberName[curr]]: {
          ...rothVsTraditional[curr],
          name: curr,
          label: memberName[curr],
        },
      });
    }
    return acc;
  }, []);
  const newOtherValues = {
    memberValues: otherValues,
  };
  return newOtherValues;
};

export const getRetirementChoicesStoreValues = (retirementChoice) => {
  const commonAttr = retirementChoicesDependentFields.reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: retirementChoice[curr],
    };
  }, {});

  const retirementChoiceStoreValue = Object.keys(retirementChoice)
    .sort()
    .reduce((obj, key) => {
      obj[key] = retirementChoice[key];
      return obj;
    }, {});
  const otherValues = Object.keys(retirementChoiceStoreValue).reduce(
    (acc, curr) => {
      if (
        retirementChoice[curr] &&
        typeof retirementChoice[curr] === 'object' &&
        Object.keys(retirementChoice[curr]).length > 0
      ) {
        acc.push({
          [memberName[curr]]: {
            ...retirementChoice[curr],
            name: curr,
            label: memberName[curr],
          },
        });
      }
      return acc;
    },
    []
  );
  const newOtherValues = {
    ...commonAttr,
    memberValues: otherValues,
  };
  return newOtherValues;
};

export const getExpenseOutputFieldValues = (plannerOutput) => {
  if (
    plannerOutput &&
    Object.keys(plannerOutput).length > 0 &&
    plannerOutput.expenseDetails &&
    plannerOutput.expenseDetails.length > 0
  ) {
    const { elements = [] } = plannerOutput.expenseDetails[0];
    if (elements.length > 0) {
      const { label, value } = elements[0];
      const fieldName = label.split(' ').join('');
      return {
        // label,
        [fieldName]: value,
      };
    }
    return {};
  }
  return {};
};

export const getPlanForVacationOutputFieldValues = (
  plannerOutput,
  responseNode
) => {
  if (plannerOutput[responseNode] && plannerOutput[responseNode].length > 0) {
    const { [responseNode]: responseObj } = plannerOutput;
    const outputFieldsRespObj = responseObj.map((response) => {
      const { elements } = response;
      const fieldObj = elements.reduce((acc, curr) => {
        const fieldName = curr.label.split(' ').join('');
        return {
          ...acc,
          [fieldName]: curr.value,
        };
      }, {});
      return { elements: { ...fieldObj }, name: response.name };
    });
    return outputFieldsRespObj;
  }
  return [];
};

export const getRothVsTradOutputValues = (
  plannerOutput,
  responseNode,
  wagesMemberDetails
) => {
  //always wagesMemberDetails and plannerOutput of rothvstrad shd be same i.e. (count of members) in both shd be same
  if (
    plannerOutput[responseNode] &&
    plannerOutput[responseNode].length > 0 &&
    wagesMemberDetails &&
    wagesMemberDetails.members &&
    wagesMemberDetails.members.length === plannerOutput[responseNode].length
  ) {
    const { [responseNode]: responseObj } = plannerOutput;
    const outputFieldsRespObj = responseObj.map((response, i) => {
      const { elements } = response;
      const fieldObj = elements.reduce((acc, curr) => {
        const fieldName = curr.label.split(' ').join('');
        return {
          ...acc,
          [fieldName]: curr.value,
        };
      }, {});
      //check the below code, its a temp fix
      const name = Object.keys(memberName).find(
        (key) => memberName[key] === response.name
      );
      const updatedName =
        wagesMemberDetails &&
        Object.keys(wagesMemberDetails).length > 0 &&
        wagesMemberDetails.members &&
        wagesMemberDetails.members.length > 0
          ? wagesMemberDetails.members[i].name
          : '';
      //till here from above commented line
      return {
        elements: { ...fieldObj },
        //old code
        // name: Object.keys(memberName).find(
        //   (key) => memberName[key] === response.name
        // ),
        //till here
        name: name !== undefined ? name : updatedName, // revisit this if the above mentioned temp issue is handled
      };
    });
    return outputFieldsRespObj;
  }
  return {};
};

export const getHeirDataOutputFieldValues = (plannerOutput) => {
  if (plannerOutput.estate && plannerOutput.estate.length > 0) {
    const { elements = [] } = plannerOutput.estate[0];
    const heirOutputFields = elements.reduce((acc, curr) => {
      const fieldName = curr.label.split(' ').join('');
      return {
        ...acc,
        [fieldName]: curr.value,
      };
    }, {});
    return heirOutputFields;
  }
  return {};
};

const getMenuOptions = (menuItemsObj) => {
  const menuItems = menuItemsObj.sort((a, b) => a.orderNo - b.orderNo);
  return menuItems.map((item) => ({
    label: item.key || item.value,
    value: item.value,
    key: item.key || item.value,
  }));
};

export const transformAllMenuItems = (allMenuItems) => {
  const horizonMenuItems = allMenuItems.filter(
    (menutItem) => menutItem.type === 'Horizon'
  );
  const displayTermMenuItems = allMenuItems.filter(
    (menuItem) => menuItem.type === 'DisplayTerm'
  );
  const lifeStyleMenuItems = allMenuItems.filter(
    (menuItem) => menuItem.type === 'LifeStyle'
  );
  const valueAsOfMenuItems = allMenuItems.filter(
    (menuItem) => menuItem.type === 'ValueAsOf'
  );
  const compoundingMenuItems = allMenuItems.filter(
    (menuItem) => menuItem.type === 'Compounding'
  );
  const taxFilingStatusMenuItems = allMenuItems.filter(
    (menuItem) => menuItem.type === 'TaxFilingStatus'
  );
  const clientTypeMenuItems = allMenuItems.filter(
    (menuItem) => menuItem.type && menuItem.type.toLowerCase() === 'clienttype'
  );
  const genderMenuItems = allMenuItems.filter(
    (menuItem) => menuItem.type && menuItem.type.toLowerCase() === 'gender'
  );
  const reminderDaysMenuItems = allMenuItems.filter(
    (menuItem) => menuItem.type && menuItem.type === 'reminderDays'
  );
  const accessLevelMenuItems = allMenuItems.filter(
    (menuItem) => menuItem.type && menuItem.type === 'accessLevel'
  );
  return {
    horizonMenuOptions: getMenuOptions(horizonMenuItems),
    displayTermMenuOptions: getMenuOptions(displayTermMenuItems),
    lifeStyleMenuOptions: getMenuOptions(lifeStyleMenuItems),
    valuesAsOfMenuOptions: getMenuOptions(valueAsOfMenuItems),
    compoundingMenuItems: getMenuOptions(compoundingMenuItems),
    taxFilingStatusMenuItems: getMenuOptions(taxFilingStatusMenuItems),
    clientTypeMenuItems: getMenuOptions(clientTypeMenuItems),
    genderMenuItems: getMenuOptions(genderMenuItems),
    reminderDaysMenuItems: getMenuOptions(reminderDaysMenuItems),
    accessLevelMenuItems: getMenuOptions(accessLevelMenuItems),
  };
};

const getDateFields = (fields) => {
  const dateFields =
    fields &&
    fields
      .filter((f) => f.type === 'date')
      .map((m) => {
        return {
          key: m.name,
          dateFormat: 'yyyy-MM-dd',
        };
      });
  return dateFields;
};
export const transformResponsePayload = (payload) => {
  const {
    moduleLifeEvents,
    moduleLifeStyle,
    moduleDebt,
    moduleRealEstate,
    ...rest
  } = payload;
  const { infoVehicles } = moduleLifeEvents;
  const { infoPreferences = {} } = moduleLifeStyle;
  const { infoStudentLoans, infoOtherLoans } = moduleDebt;
  const { infoPrimaryHome, infoSecondHomes, infoInvProps } = moduleRealEstate;
  const vehicleDateFields = getDateFields(planForVehicleConstants);
  const studentLoanDateFields = getDateFields(studentLoanConstants);
  const otherLoanDateFields = getDateFields(otherLoansConstants);
  const primaryHomeDateFields = getDateFields(primaryHomeConstants);
  const secondaryHomeDateFields = getDateFields(secondHomesConstants);
  const investmentPropDateFields = getDateFields(investmentPropertiesConstants);
  const tempInfoPreferences = { ...infoPreferences };
  // delete tempInfoPreferences?.runCode;

  const responsePayload = {
    moduleLifeEvents: {
      ...moduleLifeEvents,
      infoVehicles: {
        ...moduleLifeEvents.infoVehicles,
        vehicles: convertToLocalTimezone(
          infoVehicles.vehicles,
          vehicleDateFields
        ),
      },
    },
    moduleLifeStyle: {
      ...moduleLifeStyle,
      infoPreferences: { ...tempInfoPreferences },
    },
    moduleDebt: {
      ...moduleDebt,
      infoStudentLoans: {
        ...moduleDebt.infoStudentLoans,
        studentLoans: convertToLocalTimezone(
          infoStudentLoans.studentLoans,
          studentLoanDateFields
        ),
      },
      infoOtherLoans: {
        ...moduleDebt.infoOtherLoans,
        otherLoans: convertToLocalTimezone(
          infoOtherLoans.otherLoans,
          otherLoanDateFields
        ),
      },
    },
    moduleRealEstate: {
      ...moduleRealEstate,
      infoPrimaryHome: {
        ...moduleRealEstate.infoPrimaryHome,
        primaryHome: convertToLocalTimezone(
          infoPrimaryHome.primaryHome,
          primaryHomeDateFields
        ),
      },
      infoSecondHomes: {
        ...moduleRealEstate.infoSecondHomes,
        secondHomes: convertToLocalTimezone(
          infoSecondHomes.secondHomes,
          secondaryHomeDateFields
        ),
      },
      infoInvProps: {
        ...moduleRealEstate.infoInvProps,
        invProp: infoInvProps.invProp.map((invProp) => {
          const { prop, ...rest } = invProp;
          return {
            prop: convertToLocalTimezone(prop, investmentPropDateFields),
            ...rest,
          };
        }),
      },
    },
    ...rest,
  };
  return responsePayload;
};

export const transformTaxStateOptions = (taxSlabs) => {
  const nonFederalTaxBody =
    taxSlabs &&
    taxSlabs.filter(
      (slab) =>
        slab.taxbody !== 'federal' &&
        slab.taxbody !== 'stddeduction' &&
        slab.taxbody !== 'longtermcapitalgains' &&
        slab.taxbody !== 'qualifieddividends'
    );
  const set = new Set();
  const uniqueTaxBodySlabs = nonFederalTaxBody.filter(
    (taxSlab) => !set.has(taxSlab.taxbody) && set.add(taxSlab.taxbody)
  );
  const taxStateOptions = uniqueTaxBodySlabs.map((taxSlab) => ({
    label: taxSlab.taxbody.toUpperCase(),
    value: taxSlab.taxbody.toUpperCase(),
    key: taxSlab.taxbody.toUpperCase(),
  }));
  return taxStateOptions.sort((option1, option2) =>
    option1.value.localeCompare(option2.value)
  );
};

export const getMemberStateValue = (planDetails) => {
  const {
    moduleLifeStyle: { infoWagesAndExpenseProfile = {} } = {},
    moduleRetirement: { infoRetirementChoices = {} } = {},
  } = planDetails;
  if (Object.keys(infoWagesAndExpenseProfile).length > 0) {
    const { first = {}, second = {} } = infoWagesAndExpenseProfile;
    return {
      commonFieldValues: {
        retirementState: infoRetirementChoices.retirementState || '',
      },
      newMembers: [
        {
          name: first?.name,
          fieldValues: {
            wages: first?.wages,
          },
        },
        {
          name: second?.name,
          fieldValues: {
            wages: second?.wages,
          },
        },
      ],
    };
  }
  return { commonFieldValues: {}, newMembers: [] };
};
