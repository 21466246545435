import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import Controls from '../controls/Controls';
import { secondHomesFormFields } from '../../constants/Client';
import { PropertyNamesMap } from '../../constants/PropertyNames';
import {
  generateProps,
  percentageConversionToUI,
  formatValueByType,
  formatValuesForUI,
  getDateValue,
  validateDateFields,
  createLocalDateFromDateString,
  parseCurrency,
} from '../../helpers/SolutionsHelper';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const SecondHomesContentGoals = forwardRef((props, ref) => {
  const { commonProps = {}, taxStateOptions = [] } = props;
  const {
    modalType,
    handleModalOpen,
    category,
    goal = {},
    setIsFormValid,
    formDetails,
  } = commonProps;
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );

  const [selectedSecondHome, setSelectedSecondHome] = useState({});
  const [secondaryHomesFormFields, setSecondaryHomesFormFields] = useState(
    secondHomesFormFields
  );

  const conditionalFields = [
    'name',
    'origOrRefiLoanAmount',
    'purchaseDate',
    'origOrRefiLoanDate',
  ];

  useEffect(() => {
    let tempSecondHomesFormFields = [...secondHomesFormFields];
    if (taxStateOptions && taxStateOptions.length > 0) {
      tempSecondHomesFormFields = tempSecondHomesFormFields.map((f) => {
        if (f.key === 'propDomicile') {
          return { ...f, options: taxStateOptions };
        }
        return f;
      });
    }
    setSecondaryHomesFormFields(tempSecondHomesFormFields);
  }, [setSecondaryHomesFormFields, taxStateOptions]);

  const isEditingRef = useRef(false);

  useImperativeHandle(ref, () => ({
    getFormObject() {
      return selectedSecondHome;
    },
  }));

  const generatePropsMemoized = useCallback(
    (keyVal, key) => generateProps(keyVal, key, 'props', planDetailsByPlanId),
    [planDetailsByPlanId] // Dependencies for memoization
  );

  useEffect(() => {
    if (isEditingRef.current) return;
    const secondHome = formDetails?.props.find((k) => k.name === goal.name);
    if (
      !secondHome &&
      modalType === 'add' &&
      Object.keys(selectedSecondHome).length === 0
    ) {
      const newRecord = Object.keys(PropertyNamesMap['props']).reduce(
        (acc, key) => {
          const keyVal = PropertyNamesMap['props'][key];
          acc[key] = generatePropsMemoized(keyVal, key);
          return acc;
        },
        {}
      );

      if (Object.keys(selectedSecondHome).length === 0) {
        const formattedNewRecord = formatValuesForUI(
          newRecord,
          secondHomesFormFields
        );
        if (
          JSON.stringify(formattedNewRecord) !==
          JSON.stringify(selectedSecondHome)
        ) {
          setSelectedSecondHome(formattedNewRecord);
        }
      }
    }

    if (secondHome && Object.keys(selectedSecondHome).length === 0) {
      const formattedHome = formatValuesForUI(
        secondHome,
        secondHomesFormFields
      );
      if (
        JSON.stringify(formattedHome) !== JSON.stringify(selectedSecondHome)
      ) {
        setSelectedSecondHome(formattedHome); // Only update if necessary
      }
    }
  }, [
    formDetails.props,
    goal.name,
    modalType,
    selectedSecondHome,
    generatePropsMemoized,
  ]);

  const validateSecondHomeNames = useCallback(
    (value) => {
      const existingSecondHome = formDetails?.props.find(
        (secondHome) => secondHome?.name.toLowerCase() === value.toLowerCase()
      );
      if (modalType === 'add' && existingSecondHome) {
        return false;
      }
      return true;
    },
    [modalType, formDetails?.props]
  );

  const validateLoanAmountField = useCallback(
    (value) => {
      const loanAmount = parseCurrency(value || 0);
      const purchasePrice = parseCurrency(
        selectedSecondHome?.purchasePrice || 0
      );

      if (isNaN(loanAmount) || isNaN(purchasePrice)) {
        return false;
      }
      // Ensure loan amount is not greater than the purchase price
      return loanAmount <= purchasePrice;
    },
    [selectedSecondHome]
  );

  const getIsValidFieldValue = useCallback(
    (key) => {
      const value = selectedSecondHome[key];
      let isValid = true;
      switch (key) {
        case 'aggMaintenanceExp':
        case 'loanAmountOutstanding':
        case 'purchasePrice':
        case 'taxBasis':
        case 'value':
          isValid = value;
          break;
        case 'origOrRefiLoanAmount':
          isValid = value !== '' ? validateLoanAmountField(value) : false;
          break;
        case 'aggMaintenanceExpGrowth':
        case 'appreciationRate':
        case 'fixedIntRate':
        case 'taxBasisGrowth':
        case 'taxRate':
          isValid = value;
          break;
        case 'loanTerm':
          isValid = value !== '' && parseInt(value) >= 0;
          break;
        case 'purchaseDate':
          const date =
            value &&
            validateDateFields(value) &&
            createLocalDateFromDateString(value);
          isValid = validateDateFields(value)
            ? modalType === 'add'
              ? date &&
              new Date(date).setHours(0, 0, 0, 0) >=
              new Date().setHours(0, 0, 0, 0)
              : true
            : false;
          break;
        case 'origOrRefiLoanDate':
          isValid = value ? validateDateFields(value) : false;
          break;
        case 'name':
          isValid = value ? validateSecondHomeNames(value) : false;
          break;
        default:
          break;
      }
      return isValid;
    },
    [
      selectedSecondHome,
      validateSecondHomeNames,
      modalType,
      validateLoanAmountField,
    ]
  );

  const checkFormValidity = useCallback(() => {
    const allFields = secondHomesFormFields.map((s) => s.key);
    const isValid = allFields.every((field) => getIsValidFieldValue(field));
    setIsFormValid(isValid);
  }, [getIsValidFieldValue, setIsFormValid]);

  const handleBlur = (field) => {
    const fieldType = secondaryHomesFormFields.find((ft) => ft.key === field);
    let formattedValue = selectedSecondHome[field];
    if (fieldType && fieldType.dataType === 'percent') {
      // Apply the formatting only on blur
      formattedValue = percentageConversionToUI(selectedSecondHome[field]);
    }
    if (field === 'purchaseDate') {
      setSelectedSecondHome({
        ...selectedSecondHome,
        [field]: formattedValue,
        origOrRefiLoanDate: formattedValue,
      });
    } else if (field === 'purchasePrice') {
      setSelectedSecondHome({
        ...selectedSecondHome,
        [field]: formattedValue,
        taxBasis: formattedValue,
        value: formattedValue,
      }); // Update the selected vehicle state
    } else if (
      field === 'origOrRefiLoanAmount'
    ) {
      setSelectedSecondHome({
        ...selectedSecondHome,
        [field]: formattedValue,
        loanAmountOutstanding: formattedValue,
        loanTerm: parseCurrency(formattedValue) === 0 ? 0:selectedSecondHome.loanTerm,
      }); // Update the selected vehicle state
    } else
      setSelectedSecondHome({ ...selectedSecondHome, [field]: formattedValue }); // Update the selected secondHome state
    isEditingRef.current = false;
  };

  const handleOnChange = (event, key) => {
    isEditingRef.current = true;
    const { value } = event?.target || '';
    const fieldType = secondaryHomesFormFields.find((ft) => ft.key === key);
    let formattedValue = value;
    if (fieldType) {
      if (fieldType.dataType === 'percent') {
        // Allow the raw value to be typed without adding "%" on every key press
        formattedValue = value; // Keep it raw for now during typing
      } else {
        formattedValue = formatValueByType(value, fieldType.dataType);
      }
    }
    const secondHome = { ...selectedSecondHome, [key]: formattedValue };
    // Update selected secondHome state to reflect changes in UI
    setSelectedSecondHome(secondHome);
  };

  useEffect(() => {
    if (selectedSecondHome && Object.keys(selectedSecondHome).length > 0) {
      checkFormValidity();
    }
  }, [formDetails, checkFormValidity, selectedSecondHome]);

  // const isDisabledFn = ({ isDisabled = false, key = '' }) => {
  //   if (isDisabled) return true;
  //   if (key === 'purchaseDate' || key === 'purchasePrice') {
  //     const { purchaseDate = '' } = selectedSecondHome || {};
  //     return purchaseDate && new Date(purchaseDate) < new Date();
  //   }
  //   return false;
  // };

  const handleDateChange = (date, field) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        field.dataType === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'
      isEditingRef.current = true;
      const fieldType = secondaryHomesFormFields.find(
        (ft) => ft.key === field.key
      );
      if (fieldType) {
        let secondHome = { ...selectedSecondHome };
        if (field.key === 'purchaseDate') {
          secondHome = {
            ...secondHome,
            [field.key]: formattedDate,
            origOrRefiLoanDate: formattedDate,
          };
        } else {
          secondHome = {
            ...secondHome,
            [field.key]: formattedDate,
          };
        }
        setSelectedSecondHome(secondHome);
      }
    }
  };

  return (
    <>
      <div className="signup-title-container">
        <span className="signup-title">
          {modalType === 'add' ? 'Add New Second Home' : 'Update Second Home'}
        </span>
        <CloseIcon
          className="signup-title-close-icon"
          onClick={() => handleModalOpen(false)}
        />
      </div>
      <div className="signup-body-container">
        <div className="insured_main_outer">
          {Object.keys(selectedSecondHome).length > 0 &&
            secondaryHomesFormFields.map((field) => {
              switch (field.type) {
                case 'text':
                  return (
                    <div className="row align-items-center">
                      <div
                        className={`col-lg-8 insured_content_outer labelStyle 
          }`}
                      >
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 insured_radio_group">
                        <input
                          type={field.type}
                          id={field.key}
                          name={field.key}
                          //disabled={modalType === 'edit' && isDisabledFn(field)}
                          disabled={modalType === 'edit' && field.isDisabled}
                          value={selectedSecondHome[field.key]}
                          className={'custom-input'} // Apply the appropriate class
                          onChange={(event) => handleOnChange(event, field.key)}
                          onBlur={() => handleBlur(field.key)}
                          placeholder=""
                        />
                      </div>
                      {conditionalFields.includes(field.key) &&
                        selectedSecondHome &&
                        Object.keys(selectedSecondHome).length > 0 &&
                        !getIsValidFieldValue(field.key) && (
                          <p className="error-text">
                            {selectedSecondHome[field.key] !== '' &&
                              selectedSecondHome[field.key] !== undefined
                              ? field.errorMessage
                              : ''}
                          </p>
                        )}
                    </div>
                  );
                case 'select':
                  return (
                    <div className="row align-items-center">
                      <div className={`col-lg-8 insured_content_outer`}>
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className={`col-lg-3 insured_radio_group`}>
                        <Controls.Select
                          name={field.key}
                          id={field.key}
                          value={selectedSecondHome[field.key]}
                          onChange={(event, index) =>
                            handleOnChange(event, field.key)
                          }
                          className={'custom-input'}
                          options={field.options.map((c) => {
                            return {
                              value: c.value,
                              label: c.label,
                            };
                          })}
                        />
                      </div>
                    </div>
                  );
                case 'date':
                  const dateValue = getDateValue(
                    selectedSecondHome[field.key],
                    field
                  );
                  return (
                    <div className="row align-items-center">
                      <div className="col-lg-8 insured_content_outer">
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 insured_radio_group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            views={[field.dataType]}
                            className="planner-input long-text-input"
                            format={
                              field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                            }
                            autoOk
                            variant="inline"
                            InputAdornmentProps={{ position: 'start' }}
                            value={dateValue}
                            onChange={(date) => handleDateChange(date, field)}
                            onBlur={() => handleBlur(field.key)}
                            inputProps={{ style: { height: '39px' } }}
                            minDate={
                              (modalType === 'add' ||
                                (modalType === 'edit' &&
                                  new Date(dateValue).setHours(0, 0, 0, 0) >=
                                  new Date().setHours(0, 0, 0, 0))) &&
                                field.key === 'origOrRefiLoanDate'
                                ? getDateValue(
                                  selectedSecondHome['purchaseDate']
                                )
                                : new Date()
                            }
                            disabled={
                              modalType === 'edit' &&
                              new Date(dateValue).setHours(0, 0, 0, 0) <
                              new Date().setHours(0, 0, 0, 0)
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
        </div>
      </div>
    </>
  );
});
export default SecondHomesContentGoals;
